import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
	post,
	get
} from './utils/http.js'
import method from './assets/js/method.js'
import iView from 'iview';
import 'iview/dist/styles/iview.css'; // 使用 CSS

import '@/assets/fonts/font.css'
import '@/assets/css/variables.css'
import '@/assets/css/common.css'
import '@/assets/icons/iconfont.css'


import {
	domain,
	methods
} from 'shared'


// import 'swiper/swiper.css'; // 导入样式文件
// import { swiper, swiperSlide } from 'vue-awesome-swiper';
 
// 全局注册Swiper组件
// Vue.component('swiper', swiper);
// Vue.component('swiper-slide', swiperSlide);

Vue.config.productionTip = false



//定义全局变量
Vue.prototype.$post = post;
Vue.prototype.$get = get;

Vue.prototype.domain=domain
Vue.prototype.method = methods

Vue.use(iView)

router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title
	}
	if (to.meta.content) {
		let head = document.getElementsByTagName('head');
		let meta = document.createElement('meta');
		document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
		document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
		meta.content = to.meta.content;
		// console.log(meta,to.meta.content)
		head[0].appendChild(meta)
	}
	next()
})

router.afterEach((to, from, next) => { //控制路由跳转时滚动条在顶部
	window.scrollTo(0, 0);
});


// 防止el-button重复点击
Vue.directive('preventReClick', {
	inserted(el, binding) {
   	  el.addEventListener('click', () => {
     	if (!el.disabled) {
      	 el.disabled = true;
      	 el.style.cursur='not-allowed'
      	 setTimeout(() => {
        	 el.disabled = false
        	 el.style.cursor ='pointer'
       	}, binding.value || 2000)
     	}
   	})
	 }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
