<template>
	<div class="logogFoot">
		<span>©{{year+this.$store.state.user.generalSetting.copyright}} <a target="_blank"
				href="https://beian.miit.gov.cn">{{this.$store.state.user.generalSetting.archivalInfo}}</a><i
				v-if="this.$store.state.user.generalSetting.businessCertificate">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;增值电信业务经营许可证:{{this.$store.state.user.generalSetting.businessCertificate}}</i>
			<span>
				<a target="_blank" v-if="this.$store.state.user.generalSetting.beijingPublicNetworkSecurity"
					href="http://www.beian.gov.cn/portal/registerSystemInfo">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;京公网安备{{this.$store.state.user.generalSetting.beijingPublicNetworkSecurity}}</a>
			</span>
		</span>
	</div>
</template>

<script>
	import navFooterInfo from '../navFooterInfo'
	export default {
		mixins: [navFooterInfo],
		data() {
			return {
				year: ''
			}
		},
		mounted() {
			var nowYear = new Date();
			this.year = nowYear.getFullYear();
			this.getLogo()
		}
	}
</script>

<style>
</style>