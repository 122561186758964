// import {
//   login,
//   logout,
//   getUserInfo,
//   getMessage,
//   getContentByMsgId,
//   hasRead,
//   removeReaded,
//   restoreTrash,
//   getUnreadCount
// } from '@/api/user'
// import {
//   setToken,
//   getToken
// } from '@/libs/util'
import navLogo from '@/assets/img/flowLogo.png'
import headPortrait from '@/assets/img/avatarImg.png'

export default {
	state: {
		userName: localStorage.userName,
		userId: '',
		isLogining: false, //用户是否登录  false 已登录   true 未登录
		avatarImgPath: headPortrait,
		navLogo: navLogo,
		footerLogo: navLogo,
		subjectColor: { //选中的主题色
			mainColor: '#1459F4',
			complementaryColors: '#004de1',
		},
		generalSetting: {
			themeColor: [{
			  hue:'0',  //色调  0：蓝色    1：绿色   2：橙色    3：红色
			  mainColor: '#1459F4',
			  complementaryColors: '#004de1',
			  checked: true
			}, {
			  hue:'1',
			  mainColor: '#00bfbf',
			  complementaryColors: '#00abab',
			  checked: false
			}, {
			  hue:'2',
			  mainColor: '#f59a23',
			  complementaryColors: '#e38100',
			  checked: false
			}, {
			  hue:'3',
			  mainColor: '#d9001b',
			  complementaryColors: '#cc0019',
			  checked: false
			}],
			webLogo: '', //前台LOGO
			footerLogo: '', //前台页脚logo
			footerQRCode: [],
			serviceTel: '',
			wechatNumber: '',
			address: '',
			copyright: '', //版权信息
			archivalInfo: '', //备案信息
			businessCertificate: '', //增值电信业务经营许可证
			beijingPublicNetworkSecurity: '', //京公网安备
			footerBackground: '#F5F7FA',
			footerFontColor: '#282828'
		},
		customerInfo: { //用户相关信息
			auditStatus: 0, //认证状态（0：审核未通过，1：审核通过， 2：待审核）
			name: '',
		},
		// token: getToken(),
		access: '',
		hasGetInfo: false,
		unreadCount: 0,
		dataRealizationtTypeIndex: 0, //设置数据变现类型选中的下标
		productInfo: { //产品详细信息
			introduction: '' //产品介绍
		},
		channelInfo: {
			channelName:'',
			channelShortName:''
		},
		reportId:'',
		currentPageModify:false,// 当前页面是否已经修改，默认未修改
		currentPageSave:false,   // 当前页面是否已经保存，默认未保存
		checkStatus:{
			aa:'你好'
		}

	},
	mutations: {
		changeChannelInfo(state,data) {
			state.channelInfo=data;
		},
		pageModify(state,data){
			state.currentPageModify=data;
		},
		setCheckStatus(state,data){
			state.checkStatus=data;
		},
		setAvatar(state, avatarPath) {
			state.avatarImgPath = avatarPath
		},
		setIsLogining(state, isLogining) {
			state.isLogining = isLogining
		},
		setNavLogo(state, navLogo) {
			state.navLogo = navLogo
		},
		setFooterLogo(state, footerLogo) {
			state.footerLogo = footerLogo
		},
		setGeneralSetting(state, generalSetting) {
			state.generalSetting = generalSetting
		},
		setSubjectColor(state, subjectColor) {
			state.subjectColor = subjectColor
		},
		setUserId(state, id) {
			state.userId = id
		},
		setUserName(state, name) {
			state.userName = name
		},
		setAccess(state, access) {
			state.access = access
		},
		setToken(state, token) {
			state.token = token
			setToken(token)
		},
		setHasGetInfo(state, status) {
			state.hasGetInfo = status
		},
		setMessageCount(state, count) {
			state.unreadCount = count
		},
		setMessageUnreadList(state, list) {
			state.messageUnreadList = list
		},
		setMessageReadedList(state, list) {
			state.messageReadedList = list
		},
		setMessageTrashList(state, list) {
			state.messageTrashList = list
		},
		setDataRealizationtTypeIndex(state, index) {
			state.dataRealizationtTypeIndex = index
		},
		setCustomerInfo(state, data) {
			state.customerInfo = data
		},
		setProductInfo(state, data) {
			state.productInfo = data
		},
		updateMessageContentStore(state, {
			msg_id,
			content
		}) {
			state.messageContentStore[msg_id] = content
		},
		moveMsg(state, {
			from,
			to,
			msg_id
		}) {
			const index = state[from].findIndex(_ => _.msg_id === msg_id)
			const msgItem = state[from].splice(index, 1)[0]
			msgItem.loading = false
			state[to].unshift(msgItem)
		}
	},
	getters: {
		messageUnreadCount: state => state.messageUnreadList.length,
		messageReadedCount: state => state.messageReadedList.length,
		messageTrashCount: state => state.messageTrashList.length
	},
	actions: {
		// 登录
		// handleLogin({
		//   commit
		// }, {
		//   userName,
		//   password
		// }) {
		//   console.log(userName)
		//   userName = userName.trim()
		//   return new Promise((resolve, reject) => {
		//     login({
		//       userName,
		//       password
		//     }).then(res => {
		//       const data = res.data
		//       commit('setToken', data.token)
		//       resolve()
		//     }).catch(err => {
		//       reject(err)
		//     })
		//   })
		// },
		// // 退出登录
		// handleLogOut({
		//   state,
		//   commit
		// }) {
		//   return new Promise((resolve, reject) => {
		//     logout(state.token).then(() => {
		//       commit('setToken', '')
		//       commit('setAccess', [])
		//       resolve()
		//     }).catch(err => {
		//       reject(err)
		//     })
		//     // 如果你的退出登录无需请求接口，则可以直接使用下面三行代码而无需使用logout调用接口
		//     // commit('setToken', '')
		//     // commit('setAccess', [])
		//     // resolve()
		//   })
		// },
		// 获取用户相关信息
		getUserInfo({
			state,
			commit
		}, {
			data
		}) {
			return new Promise((resolve, reject) => {
				try {
					// commit('setAvatar', data.avatar)
					commit('setUserName', data.name)
					// commit('setUserId', data.user_id)
					// commit('setAccess', data.access)
					// commit('setHasGetInfo', true)
				} catch (error) {
					reject(error)
				}
			})
		},
		getsAvatar({
			state,
			commit
		}, {
			headPortrait
		}) {
			return new Promise((resolve, reject) => {
				try {
					commit('setAvatar', headPortrait)
				} catch (error) {
					reject(error)
				}
			})
		},
		getChannelInfo({
			commit
		}, {
			info
		}) {
			return new Promise((resolve, reject) => {
				try {
					commit('changeChannelInfo', info)
				} catch (error) {
					reject(error)
				}
			})
		},
		// 改左侧导航的logo
		getNavLogo({
			commit
		}, {
			navLogo
		}) {
			return new Promise((resolve, reject) => {
				try {
					console.log('执行替换导航栏logo了', navLogo)
					commit('setNavLogo', navLogo)
				} catch (error) {
					reject(error)
				}
			})
		},
		getFooterLogo({
			commit
		}, {
			footerLogo
		}) {
			return new Promise((resolve, reject) => {
				try {
					console.log('执行替换页脚logo了', footerLogo)
					commit('setFooterLogo', footerLogo)
				} catch (error) {
					reject(error)
				}
			})
		},
		getGeneralSetting({
			commit
		}, {
			generalSetting
		}) {
			return new Promise((resolve, reject) => {
				try {
					// console.log('执行通用设置', generalSetting)
					commit('setGeneralSetting', generalSetting)
				} catch (error) {
					reject(error)
				}
			})
		},
		getSubjectColor({
			commit
		}, {
			subjectColor
		}) {
			return new Promise((resolve, reject) => {
				try {
					commit('setSubjectColor', subjectColor)
				} catch (error) {
					reject(error)
				}
			})
		},
		getIsLogining({
			commit
		}, {
			isLogining
		}) {
			commit('setIsLogining', isLogining)
		},
		getDataRealizationtTypeIndex({
			commit
		}, {
			index
		}) {
			commit('setDataRealizationtTypeIndex', index)
		},
		getCustomer({
			commit
		}, {
			data
		}) {
			commit('setCustomerInfo', data)
		},
		getProductInfo({
			commit
		}, {
			data
		}) {
			commit('setProductInfo', data)
		},


	}
}