<template>
	<div>
		<footer :style="{'background':this.$store.state.user.generalSetting.footerBackground}">
			<div class="footer-container">
				<div class="footer-logo">
					<img :src="this.$store.state.user.generalSetting.footerLogo" alt="logo">
				</div>
				<div class="customerServiceInformation">
					<div class="serviceTel">
						<p :style="{'color':this.$store.state.user.generalSetting.footerFontColor}">
							<i class="iconfont icon-dianhua"></i>
							<span><i>客服电话：</i><i>{{this.$store.state.user.generalSetting.serviceTel}}</i></span>
						</p>
						<p :style="{'color':this.$store.state.user.generalSetting.footerFontColor}" v-if="this.$store.state.user.generalSetting.wechatNumber">
							<i class="iconfont icon-weixin"></i>
							<span><i>客服微信号：</i><i>{{this.$store.state.user.generalSetting.wechatNumber}}</i></span>
						</p>
						<p :style="{'color':this.$store.state.user.generalSetting.footerFontColor}">
							<i class="iconfont icon-dizhi"></i>
							<span><i>地址：</i><i>{{this.$store.state.user.generalSetting.address}}</i></span>
						</p>
					</div>
					<div class="QR_code">
						<div class="wechartCode" v-for="(item,index) in this.$store.state.user.generalSetting.footerQRCode"
							:key="index">
							<img :src="item.imgPath">
							<p :style="{'color':$store.state.user.generalSetting.footerFontColor}">{{item.describe}}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="footer-floor2">
				<p :style="{'color':this.$store.state.user.generalSetting.footerFontColor}">
					©{{year+this.$store.state.user.generalSetting.copyright}} <a target="_blank"
						:style="{'color':this.$store.state.user.generalSetting.footerFontColor}"
						href="https://beian.miit.gov.cn">{{this.$store.state.user.generalSetting.archivalInfo}}</a><i
						v-if="this.$store.state.user.generalSetting.businessCertificate">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;增值电信业务经营许可证:{{this.$store.state.user.generalSetting.businessCertificate}}</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<a target="_blank" v-if="this.$store.state.user.generalSetting.beijingPublicNetworkSecurity"
						:style="{'color':this.$store.state.user.generalSetting.footerFontColor}"
						href="http://www.beian.gov.cn/portal/registerSystemInfo">{{this.$store.state.user.generalSetting.beijingPublicNetworkSecurity}}</a>
				</p>
			</div>
		</footer>
		<div id="mobile_footeer">
			<div class="QR_code">
				<div class="wechartCode" v-for="(item,index) in this.$store.state.user.generalSetting.footerQRCode"
					:key="index">
					<img :src="item.imgPath">
					<p :style="{'color':$store.state.user.generalSetting.footerFontColor}">{{item.describe}}</p>
				</div>
			</div>
			<div class="copyright">Copyright ©{{year+this.$store.state.user.generalSetting.copyright}}</br>版权所有
				增值电信业务经营许可证:{{this.$store.state.user.generalSetting.businessCertificate}}</br>
				{{this.$store.state.user.generalSetting.beijingPublicNetworkSecurity?this.$store.state.user.generalSetting.beijingPublicNetworkSecurity:''}}
			</div>
		</div>

	</div>
</template>

<script>
	import '../../assets/css/common.css';
	import API_CONFIG from "../../../vue.config.js"
	export default {
		data() {
			return {
				year: '',
				flowUrl: this.domain.testUrl
			}
		},
		mounted() {
			var nowYear = new Date();
			this.year = nowYear.getFullYear();
		}
	}
</script>

<style scoped>
	footer {
		min-width: 1200px;
		width: 100%;
		height: auto;
		overflow: hidden;
	}

	.footer-container {
		width: 1200px;
		margin: 0 auto;
		color: #d6d6d6;
		overflow: hidden;
		position: relative;
		padding-top: 30px;
	}

	.QR-code {
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -77px;
	}

	.QR-code>div {
		float: left;
		width: 124px;
	}

	.app-code {
		margin-right: 42px;
	}

	.QR-code>div>img {
		width: 100%;
	}

	.footer-container .QR-code>div>p {
		width: 100%;
		margin: 12px 0 0 0;
		padding: 0;
		text-align: center;
	}

	.footer-logo {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 5px;
		border-bottom: 1px solid #D4D7DC;
	}

	.footer-logo>img {
		width: 238px;
	}

	.footer-logo>ul {
		padding-right: 80px;
	}

	.footer-logo>ul li a {
		font-size: 14px;
		color: #282828;
	}

	.customerServiceInformation {
		padding: 0 64px 19px 75px;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #D4D7DC;
	}

	.serviceTel {
		width: 66%;
	}

	.serviceTel p img {
		width: 18px;
		vertical-align: middle;
		margin-right: 8px;
	}

	.serviceTel p .iconfont {
		font-size: 20px;
		vertical-align: middle;
		margin-right: 8px;
	}

	.serviceTel p {
		font-size: 14px;
		margin-bottom: 10px;
	}

	.serviceTel p:first-child {
		margin-top: 29px;
	}

	.serviceTel p span i:first-child {
		/* width: 85px; */
		display: inline-block;
	}

	.QR_code {
		overflow: hidden;
		padding: 15px;
	}

	.QR_code p {
		font-size: 14px;
		font-weight: 400;
		line-height: 14px;
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.QR_code>div {
		width: 146px;
		float: left;
		text-align: center;

		img {
			width: 127px;
			height: 127px;
			object-fit: contain;
		}
	}

	.footer-floor2 {
		width: 1168px;
		margin: 0 auto;
		text-align: center;
	}

	.footer-floor2 p {
		text-align: center;
		font-size: 12px;
		line-height: 66px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.footer-floor2 a {
		cursor: pointer;
	}

	.friendlyLink {
		margin: 20px auto;
	}

	.friendlyLink a {
		margin-right: 20px;
		font-size: 12px;
	}

	#mobile_footeer {
		display: none;
	}

	@media screen and (max-width: 750px) {
		footer {
			/* display: none; */
		}

		#mobile_footeer {
			/* display: block; */
			background: #3c4860;
		}

		.QR_code {
			width: 80%;
			padding: 20px 0;
			display: flex;
			justify-content: space-between;
			margin: 0 auto;
		}

		.QR_code>div img {
			width: 76px;
		}

		.QR_code p {
			font-size: 14PX;
			padding-top: 10px;
			color: #fff;
		}

		.wechartCode,
		.onlineConsult {
			text-align: center;
		}

		.copyright {
			font-size: 12PX;
			color: #fff;
			text-align: center;
			padding: 20px;
		}
	}
</style>