<template>
	<div>
		<div class="realizationType">
			<p>{{name}}类型</p>
			<ul>
				<li v-for="(item,index) in realizationType"
					:class="[$store.state.user.dataRealizationtTypeIndex === index?'active':'']"
					:style="{'background':$store.state.user.dataRealizationtTypeIndex === index?hexToRgb($store.state.user.subjectColor.mainColor, '0.1'):'transparent'}"
					@click="choice(index,item.type)">
					<i :class="['iconfont',item.icon]"
						:style="{'color':$store.state.user.dataRealizationtTypeIndex === index?$store.state.user.subjectColor.mainColor:'#282828','fontSize':'24px'}"></i>
					<span>{{item.name}}</span>
				</li>
			</ul>
			<img :src="setImg('realization/type','png')" />
		</div>
	</div>
</template>

<script>
	import qs from 'qs'
	import axios from 'axios'
	import publicMethod from '@/views/publicMethod'
	import {
		Content
	} from 'iview'

	import {
		mapActions,
		mapMutations
	} from 'vuex'


	export default {
		mixins: [publicMethod],
		props: {
			name: {
				type: String
			}
		},
		data() {
			return {
				realizationType: [{
					name: '数据资产API',  
					icon: 'icon-APIliebiao',
					type:'7'
				}, {
					name: '股权融资',  
					icon: 'icon-zongchanzhi',
					type:'6'
				}, {
					name: '数据资产质押融资',  
					icon: 'icon-shujuzichan',
					type:'5'
				}, {
					name: '数据资产通证',  
					icon: 'icon-tubiaoai_shujuzhongxinshitu',
					type:'4'
				}, {
					name: '数据资产债券融资',  
					icon: 'icon-zhangtaoguanli',
					type:'3'
				}, {
					name: '数据资产证券化',  
					icon: 'icon-016-stockexchangeapp',
					type:'2'
				}, {
					name: '数据保险', 
					icon: 'icon-shujutansuoshujuzhongxinmoxingguanli',
					type:'1'
				}],


			}
		},
		mounted() {
			this.getDataProductTypeList()

		},
		methods: {
			...mapActions([
				'getDataRealizationtTypeIndex'
			]),
			choice(index, type) {
				this.$emit('on-select', type);

				this.getDataRealizationtTypeIndex({
					index
				})
			},
			getDataProductTypeList() {
				this.$post(this.domain.dataRealization + 'dataProductType/getDataProductTypeList', qs.stringify({}))
					.then((res) => {
						console.log('变现类型')
						console.log(res.data)

						this.$emit('on-select', res.data.content[this.$store.state.user.dataRealizationtTypeIndex].type);

						this.realizationType = res.data.content
						this.realizationType.map(el => {
							if (el.type == "7") {
								el.icon = 'icon-APIliebiao'
							} else if (el.type == "6") {
								el.icon = 'icon-zongchanzhi'
							} else if (el.type == "5") {
								el.icon = 'icon-shujuzichan'
							} else if (el.type == "4") {
								el.icon = 'icon-tubiaoai_shujuzhongxinshitu'
							} else if (el.type == "3") {
								el.icon = 'icon-zhangtaoguanli'
							} else if (el.type == "2") {
								el.icon = 'icon-016-stockexchangeapp'
							} else if (el.type == "1") {
								el.icon = 'icon-shujutansuoshujuzhongxinmoxingguanli'
							} else {
								el.icon = 'icon-APIliebiao'
							}


						})
					});


			},




		}


	}
</script>

<style scoped lang="less">
	.realizationType {
		width: 240px;
		min-height: 680px;
		background: #fff;
		background-size: cover;
		margin-right: 30px;
		box-shadow: 0px 0px 13px 0px rgba(86, 136, 205, 0.23);
		box-sizing: border-box;
		padding-bottom: 150px;
		position: relative;


		img {
			position: absolute;
			left: 0;
			bottom: 0%;
		}

		>p {
			height: 70px;
			line-height: 70px;
			background: var(--mainColor);
			padding-left: 20px;
			font-size: 20px;
			font-weight: bold;
			color: #fff;
			margin-bottom: 10px;
		}

		>ul {

			li {
				height: 63px;
				line-height: 63px;
				padding-left: 20px;
				font-size: 16px;
				color: #282828;
				border-left: 2px solid #fff;
				cursor: pointer;

				span {
					margin-left: 12px;
					color: #282828;
				}
			}

			li.active {
				border-color: var(--mainColor);

				span {
					color: var(--mainColor);
				}
			}
		}
	}
</style>