<template>
	<div class="reportPage webReport">
		<div class="report-container">
			<div class="tools">
				<button @click="exportPDF">导出PDF</button>
			</div>

			<div id="report-page">
				<!-- 封面 -->
				<div class="page homePage" id="homePage">
					<div class="logoBox">
						<img v-if="pageInfo.firstPageImage" :src="pageInfo.firstPageImage" alt="公司logo" />
					</div>
					<p class="companyName" v-text="pageInfo.baseInfo.companyName">北京AAAA科技有限公司</p>
					<div class="homePageLine"></div>
					<h2>财务分析及预警报告</h2>

					<h3 class="reportId" v-text="pageInfo.reportNumber"></h3>
					<img src="../../assets/img/financialEarlyWarning/pageIndex_bg.png" alt="财务预警" class="pageIndex_bg">
					<i>{{pageInfo.channelName}}保留最终解释权</i>
				</div>
				<div class="page cataloguePage" id="cataloguePage">
					<div>
						<h2>目录</h2>
						<h3>CONTECT</h3>
						<div class="generalize">
							<div>
								<div></div>
								<p>一、</p>
								<p>基本信息</p>
								<span class="titleActive"></span>
								<span class="pageNumActive fontBold">01</span>
							</div>
							<i class="titleLine"></i>
							<div>
								<div></div>
								<p>二、</p>
								<p>盈利分析</p>
								<span class="titleActive"></span>
								<span class="pageNumActive fontBold">01</span>
							</div>
							<i class="titleLine"></i>
							<ul class="flowChart">
								<li>
									<p>总资产净利率</p>
									<span class="dottedLine" style="width: 804px;"></span>
									<span class="pageNumber">01</span>
								</li>
								<li>
									<p>销售费用率</p>
									<span class="dottedLine" style="width: 822px;"></span>
									<span class="pageNumber">02</span>
								</li>
								<li>
									<p>管理费用率</p>
									<span class="dottedLine" style="width: 822px;"></span>
									<span class="pageNumber">02</span>
								</li>
								<li>
									<p>研发费用率</p>
									<span class="dottedLine" style="width: 822px;"></span>
									<span class="pageNumber">03</span>
								</li>
								<li>
									<p>毛利率</p>
									<span class="dottedLine" style="width: 859px;"></span>
									<span class="pageNumber">03</span>
								</li>
								<li>
									<p>净利润率</p>
									<span class="dottedLine" style="width: 841px;"></span>
									<span class="pageNumber">04</span>
								</li>
							</ul>
							<div>
								<div></div>
								<p>三、</p>
								<p>财务分析</p>
								<span class="titleActive"></span>
								<span class="pageNumActive fontBold">04</span>
							</div>
							<i class="titleLine"></i>
							<ul>
								<li>
									<p>主营业务收入增长率</p>
									<span class="dottedLine" style="width: 750px;"></span>
									<span class="pageNumber">04</span>
								</li>
								<li>
									<p>净利润增长率</p>
									<span class="dottedLine" style="width: 805px;"></span>
									<span class="pageNumber">04</span>
								</li>
								<li>
									<p>净资产收益率</p>
									<span class="dottedLine" style="width: 805px;"></span>
									<span class="pageNumber">05</span>
								</li>
							</ul>
							<div>
								<div></div>
								<p>四、</p>
								<p>偿债分析</p>
								<span class="titleActive"></span>
								<span class="pageNumActive fontBold">06</span>
							</div>
							<i class="titleLine"></i>
							<ul>
								<li>
									<p>企业资产负债率</p>
									<span class="dottedLine" style="width: 786px;"></span>
									<span class="pageNumber">06</span>
								</li>
								<li>
									<p>流动比率</p>
									<span class="dottedLine" style="width: 840px;"></span>
									<span class="pageNumber">06</span>
								</li>
								<li>
									<p>速动比率</p>
									<span class="dottedLine" style="width: 840px;"></span>
									<span class="pageNumber">07</span>
								</li>
								<li>
									<p>EBIT利息保障倍数</p>
									<span class="dottedLine" style="width: 768px;"></span>
									<span class="pageNumber">07</span>
								</li>
							</ul>
							<div>
								<div></div>
								<p>五、</p>
								<p>运营分析</p>
								<span class="titleActive"></span>
								<span class="pageNumActive fontBold">08</span>
							</div>
							<i class="titleLine"></i>
							<ul>
								<li>
									<p>应收账款周转率</p>
									<span class="dottedLine" style="width: 786px;"></span>
									<span class="pageNumber">08</span>
								</li>
								<li>
									<p>总资产周转率</p>
									<span class="dottedLine" style="width: 804px;"></span>
									<span class="pageNumber">08</span>
								</li>
								<li>
									<p>存货周转率</p>
									<span class="dottedLine" style="width: 822px;"></span>
									<span class="pageNumber">09</span>
								</li>
							</ul>
							<div>
								<div></div>
								<p>六、</p>
								<p>杜邦分析</p>
								<span class="titleActive"></span>
								<span class="pageNumActive fontBold">10</span>
							</div>
							<i class="titleLine"></i>
							<div>
								<div></div>
								<p>七、</p>
								<p>体检结果</p>
								<span class="titleActive"></span>
								<span class="pageNumActive fontBold">11</span>
							</div>
							<i class="titleLine"></i>
							<div>
								<div></div>
								<p>八、</p>
								<p>附件</p>
								<span class="titleActive"></span>
								<span class="pageNumActive fontBold">12</span>
							</div>
							<i class="titleLine"></i>
							<div>
								<div></div>
								<p>九、</p>
								<p>计算公式</p>
								<span class="titleActive"></span>
								<span class="pageNumActive fontBold">16</span>
							</div>
							<i v-if="pageInfo.introImage" class="titleLine"></i>
							<div v-if="pageInfo.introImage">
								<div></div>
								<p>十、</p>
								<p>股交所简介</p>
								<span class="titleActive"></span>
								<span class="pageNumActive fontBold">17</span>
							</div>
							<i class="titleLine"></i>
							<div class="isAnd_catalogue">
								<div></div>
								<p class="toTen">{{pageInfo.introImage?'十一':'十'}}、</p>
								<p>免责声明</p>
								<span class="titleActive"></span>
								<span class="pageNumActive fontBold isAnd_">{{pageInfo.introImage?18:17}}</span>
							</div>
							<i class="titleLine"></i>
						</div>
					</div>
				</div>
				<div class="page" id="page-baseInfo">
					<div class="page-content">
						<div class="basicInfo">
							<h2 class="classHeading">一、基本信息</h2>
							<div class="basicInfoCont">
								<ul>
									<li>
										<span>企业名称：</span>
										<span class="companyName" style="width: 76%;" v-text="pageInfo.baseInfo.companyName"></span>
									</li>
									<li>
										<span>企业简称：</span>
										<span class="companyName" style="width: 76%;" v-text="pageInfo.baseInfo.companyShort"></span>
									</li>
									<li>
										<span>所属行业：</span>
										<span class="industry" style="width: 76%;"
											v-text="pageInfo.baseInfo.industryName + '-' + pageInfo.baseInfo.industryChildName"></span>
									</li>
									<li>
										<span>财务分析基准日：</span>
										<span v-text="pageInfo.baseInfo.diagnoseBaseDate"></span>
									</li>
								</ul>
							</div>
						</div>
						<div>
							<div class="valueResultTitle">
								<img src="../../assets/img/reportValuation/caiwufenxi.png" alt="财务分析" class="icon_">
								<p>财务分析</p>
							</div>
							<DrawRadarCanvas :chartData="pageInfo.financial" :id="'financeAnalyze_BaseInfo'" v-if="initStatus" />
						</div>
						<div>
							<h2 class="classHeading">二、盈利分析</h2>
							<!--总资产收益率-->
							<DrawBarFinancialAnalysis2v :id="'totalAssetsReturnOn'" :chartData="pageInfo.totalAssetsReturnOn"
								v-if="initStatus" :gradeStatus="initStatus" />

							<!--销售费用率-->
							<!-- <DrawBarFinancialAnalysis2v :id="'saleRatio'" :chartData="pageInfo.saleRatio" v-if="initStatus"
								:gradeStatus="initStatus" /> -->
						</div>
					</div>
					<div class="pageNum">
						<span>1</span>
					</div>
				</div>
				<div class="page" id="page-profitAnalyze">
					<div class="page-content">
						<div>
							<!--销售费用率-->
							<DrawBarFinancialAnalysis2v :id="'saleRatio'" :chartData="pageInfo.saleRatio" v-if="initStatus"
								:gradeStatus="initStatus" />

							<!--管理费用率-->
							<DrawBarFinancialAnalysis2v :id="'manageRatio'" :chartData="pageInfo.manageRatio" v-if="initStatus"
								:gradeStatus="initStatus" />
						</div>
					</div>
					<div class="pageNum">
						<span>2</span>
					</div>
				</div>
				<div class="page" id="page-profitAnalyze1">
					<div class="page-content">
						<div>
							<!--研发费用率-->
							<DrawBarFinancialAnalysis2v :id="'researchRatio'" :chartData="pageInfo.researchRatio" v-if="initStatus"
								:gradeStatus="initStatus" />

							<!--毛利率-->
							<DrawBarFinancialAnalysis2v :id="'grossProfitRatio'" :chartData="pageInfo.grossProfitRatio"
								v-if="initStatus" :gradeStatus="initStatus" />
						</div>
					</div>
					<div class="pageNum">
						<span>3</span>
					</div>
				</div>
				<div class="page" id="page-profitAnalyze2">
					<div class="page-content">
						<div>
							<!--净利润率-->
							<DrawBarFinancialAnalysis2v :id="'netProfiltRatio'" :chartData="pageInfo.netProfiltRatio"
								v-if="initStatus" :gradeStatus="initStatus" />
						</div>
						<div>
							<h2 class="classHeading">三、成长分析</h2>
							<!--主营业务收入增长率-->
							<DrawBarFinancialAnalysis2v :id="'mainIncomeRatio'" :chartData="pageInfo.mainIncomeRatio"
								v-if="initStatus" :gradeStatus="initStatus" />
						</div>
					</div>
					<div class="pageNum">
						<span>4</span>
					</div>
				</div>
				<div class="page" id="page-growthAnalyze">
					<div class="page-content">
						<div>
							<!--利润总额增长率-->
							<DrawBarFinancialAnalysis2v :id="'totalProfitRatio'" :chartData="pageInfo.totalProfitRatio"
								v-if="initStatus" :gradeStatus="initStatus" />

							<!--净资产收益率-->
							<DrawBarFinancialAnalysis2v :id="'netAssetRatio'" :chartData="pageInfo.netAssetRatio" v-if="initStatus"
								:gradeStatus="initStatus" />
						</div>
					</div>
					<div class="pageNum">
						<span>5</span>
					</div>
				</div>
				<div class="page" id="page-debtPayAnalyze">
					<div class="page-content">
						<div>
							<h2 class="classHeading">四、偿债分析</h2>
							<!--资产负债率-->
							<DrawBarFinancialAnalysis2v :id="'liabilitiesRatio'" :chartData="pageInfo.liabilitiesRatio"
								v-if="initStatus" :gradeStatus="initStatus" />
							<!--流动比率-->
							<DrawBarFinancialAnalysis2v :id="'flowRation'" :chartData="pageInfo.flowRation" v-if="initStatus"
								:gradeStatus="initStatus" />
						</div>
					</div>
					<div class="pageNum">
						<span>6</span>
					</div>
				</div>
				<div class="page" id="page-debtPayAnalyze1">
					<div class="page-content">
						<div>
							<!--速动比率-->
							<DrawBarFinancialAnalysis2v :id="'quickRatio'" :chartData="pageInfo.quickRatio" v-if="initStatus"
								:gradeStatus="initStatus" />
							<!--EBIT利息保障倍数-->
							<DrawBarFinancialAnalysis2v :id="'ebitMultiple'" :chartData="pageInfo.ebitMultiple" v-if="initStatus"
								:gradeStatus="ebitMultipleGradeStatus" />
						</div>
					</div>
					<div class="pageNum">
						<span>7</span>
					</div>
				</div>
				<div class="page" id="page-operateAnalyze">
					<div class="page-content">
						<div>
							<h2 class="classHeading">五、运营分析</h2>
							<!--应收账款周转率-->
							<DrawBarFinancialAnalysis2v :id="'receivablesTurnoverRatio'"
								:chartData="pageInfo.receivablesTurnoverRatio" v-if="initStatus"
								:gradeStatus="receivablesTurnoverRatioGradeStatus" />
							<!--总资产周转率-->
							<DrawBarFinancialAnalysis2v :id="'totalAssetsTurnoverRatio'"
								:chartData="pageInfo.totalAssetsTurnoverRatio" v-if="initStatus" :gradeStatus="initStatus" />
						</div>
					</div>
					<div class="pageNum">
						<span>8</span>
					</div>
				</div>
				<div class="page" id="page-operateAnalyze1">
					<div class="page-content">
						<div>
							<!--存货周转率-->
							<DrawBarFinancialAnalysis2v :id="'inventoryTurnoverRatio'" :chartData="pageInfo.inventoryTurnoverRatio"
								v-if="initStatus" :gradeStatus="inventoryTurnoverRatioGradeStatus" />
						</div>
					</div>
					<div class="pageNum">
						<span>9</span>
					</div>
				</div>
				<div class="page dupontAnalyze" id="page-dupon">
					<div class="page-content">
						<h2 class="classHeading">六、杜邦分析</h2>
						<!--杜邦分析标题-->
						<DupontAnalyze v-if="initStatus" :versions="0" :dupontData="pageInfo.dubangAnalysis" />
					</div>
					<div class="pageNum">
						<span>10</span>
					</div>
				</div>
				<div class="page financialCondition" id="page-financialCondition">
					<div class="page-content">
						<h2 class="classHeading">七、体检结果</h2>
						<div class="valueResultTitle">
							<img src="../../assets/img/reportValuation/caiwuzhuangkuang.png" alt="财务状况体检报告" class="icon_">
							<p>财务状况体检报告</p>
						</div>
						<!--圆环内容-->
						<ul class="imgUl">
							<li v-for="(item,index) in pageInfo.financial" :key="index + 7">
								<div class="liDivImg" id="indicatorContainer1" :style="item.style">
									<p>{{item.value}}</p>
									<h6>{{item.section}}</h6>
								</div>
								<h5>{{item.name}}</h5>
								<img src="../../assets/img/growthDiagnosis/scoreLow.svg" alt="" class="scoreLow"
									v-if="financialIndex == index">
								<p>{{item.description}}</p>
							</li>
						</ul>
					</div>
					<div class="pageNum">
						<span>11</span>
					</div>
				</div>
				<div class="page" id="accessory_1">
					<div class="page-content">
						<p class="accessoryTitle">
							<i>附件1</i>
							<i>{{pageInfo.baseInfo.companyName}}资产负债表</i>
							<i>单位：元</i>
						</p>

						<LibTableComponent1 :liaTableInfo="pageInfo.liaTableInfo" v-if="initStatus" :year="pageInfo.year"
							:versions="0" :type="7" />

					</div>
					<div class="pageNum">
						<span>12</span>
					</div>
				</div>
				<div class="page" id="accessory_2">
					<div class="page-content">
						<p class="accessoryTitle">
							<i>附件1</i>
							<i>{{pageInfo.baseInfo.companyName}}资产负债表</i>
							<i>单位：元</i>
						</p>

						<LibTableComponent2 :liaTableInfo="pageInfo.liaTableInfo" v-if="initStatus" :year="pageInfo.year"
							:versions="0" :type="7" />

					</div>
					<div class="pageNum">
						<span>13</span>
					</div>
				</div>
				<div class="page" id="accessory_3">
					<div class="page-content">
						<p class="accessoryTitle">
							<i>附件2</i>
							<i>{{pageInfo.baseInfo.companyName}}利润表</i>
							<i>单位：元</i>
						</p>

						<div>
							<table class="table profitStatement" border="0" style="border-collapse: collapse;">
								<thead>
									<tr class="noInput">
										<th style="width: 38%;">项目</th>
										<th style="width: 30%;" v-for="(item,index) in pageInfo.year" :key="index - 1">
											{{item.substring(0,4)+'年度'}}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr class="total">
										<td class="trOne">一、营业收入</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 1">
											{{judgingCondition('income',index)?method.formateNum(profitTableInfo.income[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trTwo">减：营业成本</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 2">
											{{judgingCondition('cost',index)?method.formateNum(profitTableInfo.cost[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trThree">税金及附加</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 3">
											{{judgingCondition('taxAdditional',index)?method.formateNum(profitTableInfo.taxAdditional[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trThree">销售费用</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 4">
											{{judgingCondition('saleCost',index)?method.formateNum(profitTableInfo.saleCost[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trThree">管理费用</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 5">
											{{judgingCondition('manageCost',index)?method.formateNum(profitTableInfo.manageCost[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trThree">研发费用</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 6">
											{{judgingCondition('researchCost',index)?method.formateNum(profitTableInfo.researchCost[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trThree">财务费用</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 7">
											{{judgingCondition('financeCost',index)?method.formateNum(profitTableInfo.financeCost[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trFour">其中：利息支出</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 8">
											{{judgingCondition('interestCost',index)?method.formateNum(profitTableInfo.interestCost[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trFive">利息收入</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 9">
											{{judgingCondition('interestIncome',index)?method.formateNum(profitTableInfo.interestIncome[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trTwo">加：其他收益</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 10">
											{{judgingCondition('otherBenefits',index)?method.formateNum(profitTableInfo.otherBenefits[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trThree">投资收益(损失以"-"号填列)</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 11">
											{{judgingCondition('investmentIncome',index)?method.formateNum(profitTableInfo.investmentIncome[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trFour">其中：对联营企业和合营企业的投资收益</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 12">
											{{judgingCondition('enterpriseIncome',index)?method.formateNum(profitTableInfo.enterpriseIncome[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trFive">以摊余成本计量的金融资产终止确认收益（损失以"-"号填列）</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 13">
											{{judgingCondition('financialAssetsBenefits',index)?method.formateNum(profitTableInfo.financialAssetsBenefits[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trThree">净敞口套期收益（损失以"-"号填列）</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 14">
											{{judgingCondition('netExposureHedgeIncome',index)?method.formateNum(profitTableInfo.netExposureHedgeIncome[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trThree">公允价值变动收益（损失以"-"号填列）</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 14">
											{{judgingCondition('fairValueIncome',index)?method.formateNum(profitTableInfo.fairValueIncome[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trThree">信用减值损失（损失以"-"号填列）</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 14">
											{{judgingCondition('creditImpairmentLoss',index)?method.formateNum(profitTableInfo.creditImpairmentLoss[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trThree">资产减值损失（损失以"-"号填列）</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 15">
											{{judgingCondition('assetLoss',index)?method.formateNum(profitTableInfo.assetLoss[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trThree">资产处置收益（损失以"-"号填列）</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 16">
											{{judgingCondition('assetDisposalIncome',index)?method.formateNum(profitTableInfo.assetDisposalIncome[index],2):'-'}}
										</td>
									</tr>
									<tr class="total">
										<td class="trOne">二、营业利润（亏损以"-"号填列）</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 17">
											{{judgingCondition('businessProfit',index)?method.formateNum(profitTableInfo.businessProfit[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trTwo">加：营业外收入</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 18">
											{{judgingCondition('outsideIncome',index)?method.formateNum(profitTableInfo.outsideIncome[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trTwo">减：营业外支出</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 19">
											{{judgingCondition('outsideExpenditure',index)?method.formateNum(profitTableInfo.outsideExpenditure[index],2):'-'}}
										</td>
									</tr>
									<tr class="total">
										<td class="trOne">三、利润总额（亏损总额以"-"号填列）</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 20">
											{{judgingCondition('totalProfit',index)?method.formateNum(profitTableInfo.totalProfit[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trTwo">减：所得税费用</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 21">
											{{judgingCondition('incomeTax',index)?method.formateNum(profitTableInfo.incomeTax[index],2):'-'}}
										</td>
									</tr>
									<tr class="total">
										<td class="trOne">四、净利润（净亏损以"-"号填列）</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 22">
											{{judgingCondition('netProfit',index)?method.formateNum(profitTableInfo.netProfit[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trTwo">（一） 按经营持续性分类</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 23"></td>
									</tr>
									<tr>
										<td class="trSix">1.持续经营净利润（净亏损以"-"号填列）</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 24">
											{{judgingCondition('goingConcernNetProfit',index)?method.formateNum(profitTableInfo.goingConcernNetProfit[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trSix">2.终止经营净利润（净亏损以"-"号填列）</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 25">
											{{judgingCondition('discontinuedOperationsNetProfit',index)?method.formateNum(profitTableInfo.discontinuedOperationsNetProfit[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trTwo">（二） 按所有权归属分类</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 26"></td>
									</tr>
									<tr>
										<td class="trSix">1.归属于母公司股东的净利润（净亏损以"-"号填列）</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 27">
											{{judgingCondition('parentCompanyNetProfit',index)?method.formateNum(profitTableInfo.parentCompanyNetProfit[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trSix">2.少数股东损益（净亏损以"-"号填列）</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 28">
											{{judgingCondition('minorityStockLegalRight',index)?method.formateNum(profitTableInfo.minorityStockLegalRight[index],2):'-'}}
										</td>
									</tr>
									<tr class="total">
										<td class="trOne">五、其他综合收益的税后净额</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 29">
											{{judgingCondition('ociNetTax',index)?method.formateNum(profitTableInfo.ociNetTax[index],2):'-'}}
										</td>
									</tr>
									<tr class="total">
										<td class="trTwo">（一）归属于母公司所有者的其他综合收益的税后净额</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 30">
											{{judgingCondition('parentCompanyOciNetTax',index)?method.formateNum(profitTableInfo.parentCompanyOciNetTax[index],2):'-'}}
										</td>
									</tr>
									<tr class="total">
										<td class="trSix">1.不能重分类进损益的其他综合收益</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 31">
											{{judgingCondition('notReclassifiedOci',index)?method.formateNum(profitTableInfo.notReclassifiedOci[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trSeven">（1）重新计量设定受益计划变动额</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 32">
											{{judgingCondition('remeasureBenefitAmount',index)?method.formateNum(profitTableInfo.remeasureBenefitAmount[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trSeven">（2）权益法下不能转损益的其他综合收益</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 33">
											{{judgingCondition('notTransferOci',index)?method.formateNum(profitTableInfo.notTransferOci[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trSeven">（3）其他权益工具投资公允价值变动</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 34">
											{{judgingCondition('otherEquityFairValue',index)?method.formateNum(profitTableInfo.otherEquityFairValue[index],2):'-'}}
										</td>
									</tr>
								</tbody>
							</table>

						</div>

					</div>
					<div class="pageNum">
						<span>14</span>
					</div>
				</div>
				<div class="page" id="accessory_4">
					<div class="page-content">
						<p class="accessoryTitle">
							<i>附件2</i>
							<i>{{pageInfo.baseInfo.companyName}}利润表</i>
							<i>单位：元</i>
						</p>
						<div>
							<table class="table profitStatement" border="0" style="border-collapse: collapse;">
								<thead>
									<tr class="noInput">
										<th style="width: 38%;">项目</th>
										<th style="width: 30%;" v-for="(item,index) in pageInfo.year" :key="index - 1">
											{{item.substring(0,4)+'年度'}}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td class="trSeven">（4）其他债权投资信用减值准备</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 40">
											{{judgingCondition('otherClaimsCreditImpairment',index)?method.formateNum(profitTableInfo.otherClaimsCreditImpairment[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trSeven">（5）现金流量套期储备</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 41">
											{{judgingCondition('cashFlowHedgingReserve',index)?method.formateNum(profitTableInfo.cashFlowHedgingReserve[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trSeven">（6）外币财务报表折算差额</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 42">
											{{judgingCondition('foreignCurrencyBalance',index)?method.formateNum(profitTableInfo.foreignCurrencyBalance[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trTwo">（二）归属于少数股东的其他综合收益的税后净额</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 43">
											{{judgingCondition('minorityStockOciNetTax',index)?method.formateNum(profitTableInfo.minorityStockOciNetTax[index],2):'-'}}
										</td>
									</tr>
									<tr class="total">
										<td class="trOne">六、综合收益总额</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 44">
											{{judgingCondition('totalComprehensiveIncome',index)?method.formateNum(profitTableInfo.totalComprehensiveIncome[index],2):'-'}}
										</td>
									</tr>
									<tr class="total">
										<td class="trTwo">（一）归属于母公司所有者的综合收益总额</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 45">
											{{judgingCondition('parentCompanyTci',index)?method.formateNum(profitTableInfo.parentCompanyTci[index],2):'-'}}
										</td>
									</tr>
									<tr class="total">
										<td class="trTwo">（一）归属于少数股东的综合收益总额</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 46">
											{{judgingCondition('minorityStockTci',index)?method.formateNum(profitTableInfo.minorityStockTci[index],2):'-'}}
										</td>
									</tr>
									<tr class="total">
										<td class="trOne">七、每股收益</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 47"></td>
									</tr>
									<tr>
										<td class="trTwo">（一）基本每股收益</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 45">
											{{judgingCondition('basicEps',index)?method.formateNum(profitTableInfo.basicEps[index],2):'-'}}
										</td>
									</tr>
									<tr>
										<td class="trTwo">（二）稀释每股收益</td>
										<td v-for="(item,index) in pageInfo.year" :key="index + 46">
											{{judgingCondition('dilutedEps',index)?method.formateNum(profitTableInfo.dilutedEps[index],2):'-'}}
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<p class="accessoryTitle" style="margin-top: 50px;">
							<i></i>
							<i>{{pageInfo.baseInfo.companyName}}财务比率表</i>
							<i></i>
						</p>

						<FinancialRatios2 :financeRatio="financeRatio" v-if="initStatus" :year="financeRatio.year" :versions="0"
							:type="11" />

					</div>
					<div class="pageNum">
						<span>15</span>
					</div>
				</div>
				<div class="page" id="page-computationalFormula">
					<div class="page-content">
						<h2 class="classHeading">九、计算公式</h2>
						<div class="formula-container">
							<h3>1、财务指标公式</h3>
							<ul>
								<li>
									<span>◆</span>
									<span>资产负债率=负债合计/资产总计×100%</span>
								</li>
								<li>
									<span>◆</span>
									<span>流动比率=流动资产合计/流动负债合计</span>
								</li>
								<li>
									<span>◆</span>
									<span>EBIT利息保障倍数=EBIT/利息费用</span>
								</li>
								<li>
									<span>◆</span>
									<span>毛利率=（营业收入-营业成本）/营业收入×100%</span>
								</li>
								<li>
									<span>◆</span>
									<span>净利润率=净利润/营业收入×100%</span>
								</li>
								<li>
									<span>◆</span>
									<span>主营业务收入增长率=（本年度营业收入-上年度营业收入）/上年度营业收入×100%</span>
								</li>
								<li>
									<span>◆</span>
									<span>净资产收益率=净利润×2/（本年度期初净资产+本年度期末净资产）×100%</span>
								</li>
								<li>
									<span>◆</span>
									<span>应收账款周转率=营业收入/平均应收账款</span>
								</li>
								<li>
									<span>◆</span>
									<span>存货周转率=营业成本/平均存货</span>
								</li>
								<li>
									<span>◆</span>
									<span>总资产净利率=净利润×2/（本期资产总额+上期资产总额）×100%</span>
								</li>
								<li>
									<span>◆</span>
									<span>销售费用率=销售费用/营业收入</span>
								</li>
								<li>
									<span>◆</span>
									<span>管理费用率=管理费用/营业收入</span>
								</li>
								<li>
									<span>◆</span>
									<span>研发费用率=研发费用/营业收入</span>
								</li>
								<li>
									<span>◆</span>
									<span>速动比率=（流动资产-存货）/流动负债</span>
								</li>
								<li>
									<span>◆</span>
									<span>总资产周转率=营业收入×2/（本期资产总额+上期资产总额）×100%</span>
								</li>
								<li>
									<span>◆</span>
									<span>利润总额增长率=（本期利润总额-上期利润总额）/上期利润总额×100%</span>
								</li>
							</ul>
						</div>

					</div>
					<div class="pageNum">
						<span>16</span>
					</div>
				</div>
				<div class="page stockIntroduce" id="stockIntroduce" v-if="pageInfo.introImage">
					<div>
						<h2 class="classHeading" style="position: absolute;top: 45px;left: 110px;">十、股交所简介</h2>
						<img style="width:100%;" :src="pageInfo.introImage" alt="简介">
					</div>
					<div class="pageNum">
						<span>17</span>
					</div>
				</div>
				<div class="page" id="page-disclaimer">
					<div class="page-content page-last">
						<h2 class="classHeading">{{pageInfo.introImage?'十一':'十'}}、免责声明</h2>
						<img src="../../assets/img/reportValuation/map@2x.png" alt="地图" />

						<div class="flow-statement">
							<img v-if="pageInfo.firstPageImage" :src="pageInfo.firstPageImage" alt="公司logo" />
							<ul>
								<li>
									<div class="statement-tit">
										<span class="xian"></span>
										<img src="../../assets/img/reportValuation/statement-01@2x.png" alt="声明" />
										<p>估值声明</p>
										<img src="../../assets/img/reportValuation/statement-01@2x.png" alt="声明" />
										<span class="xian"></span>
									</div>
									<div class="statement-main">
										本报告由系统自动生成，主要依据企业提供的财务数据、权威的第三方数据库、通用的行业均值和可比公司相关数据。企业提供资料的完整度和准确性对报告有重要影响。请本报告使用者知悉。
									</div>
								</li>
								<li>
									<div class="statement-tit">
										<span class="xian"></span>
										<img src="../../assets/img/reportValuation/statement-01@2x.png" alt="声明" />
										<p>免责声明</p>
										<img src="../../assets/img/reportValuation/statement-01@2x.png" alt="声明" />
										<span class="xian"></span>
									</div>
									<div class="statement-main">
										系统所采用的数据信息来源于企业自行提供的资料和公开渠道可获取的行业信息和数据，并基于前述资料属实、合法的假设出具本报告，网站亦不对前述资料的准确性、完整性负有实质审核的义务，本报告仅作为对企业整体价值衡量的参考，非并购、转让等交易的直接证据，网站及其运营方不承担因信赖本报告信息而采取行动所导致的任何损失及一切法律责任，北京心流慧估科技有限公司提供技术支持。
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div class="pageNum">
						<span>{{pageInfo.introImage?18:17}}</span>
					</div>
				</div>


			</div>


			<div id="loading">
				<div class='base'>
					<div class='cube'></div>
					<div class='cube'></div>
					<div class='cube'></div>
					<div class='cube'></div>
					<div class='cube'></div>
					<div class='cube'></div>
					<div class='cube'></div>
					<div class='cube'></div>
					<div class='cube'></div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import qs from 'qs'
	import axios from 'axios'
	import PdfOnload from '@/utils/pdf'
	import '@/assets/css/reportCommon.css'

	import DrawRadarCanvas from '@/common/echarts/drawRadarCanvas.vue'
	import DrawBarFinancialAnalysis2v from '@/common/echarts/drawBarFinancialAnalysis2v.vue'
	import DupontAnalyze from '@/components/reportComponent/dupontAnalyze.vue'
	import LibTableComponent1 from '@/components/reportComponent/liaTableComponent1.vue'
	import LibTableComponent2 from '@/components/reportComponent/liaTableComponent2.vue'
	import ProfitTableComponent1 from '@/components/reportComponent/profitTableComponent1.vue'
	import ProfitTableComponent2 from '@/components/reportComponent/profitTableComponent2.vue'
	import FinancialRatios2 from '@/components/reportComponent/financialRatios2.vue'


	export default {
		components: {
			DrawRadarCanvas,
			DrawBarFinancialAnalysis2v,
			DupontAnalyze,
			LibTableComponent1,
			LibTableComponent2,
			ProfitTableComponent1,
			ProfitTableComponent2,
			FinancialRatios2

		},
		data() {
			return {
				url: this.domain.ajaxUrl + this.domain.systemName + this.domain.earlyWarningAjaxUrl,
				stage: '',
				type: '11',
				typeName: '财务预警',
				pdfArr: [],
				pdfIndex: 0,
				pageInfo: {
					baseInfo: {
						companyName: "",
						establishDate: ""
					},
					year: []
				},
				// 电池展示状态
				gradeStatus: 1,
				ebitMultipleGradeStatus: 1,
				receivablesTurnoverRatioGradeStatus: 1,
				inventoryTurnoverRatioGradeStatus: 1,
				financialIndex: 3,
				profitTableInfo: {
					income: [],
					cost: [],
					taxAdditional: [],
					saleCost: [],
					manageCost: [],
					researchCost: [],
					financeCost: [],
					interestCost: [],
					interestIncome: [],
					otherBenefits: [],
					investmentIncome: [],
					enterpriseIncome: [],
					financialAssetsBenefits: [],
					netExposureHedgeIncome: [],
					fairValueIncome: [],
					creditImpairmentLoss: [],
					assetLoss: [],
					assetDisposalIncome: [],
					businessProfit: [],
					outsideIncome: [],
					outsideExpenditure: [],
					totalProfit: [],
					incomeTax: [],
					netProfit: [],
					goingConcernNetProfit: [],
					discontinuedOperationsNetProfit: [],
					parentCompanyNetProfit: [],
					minorityStockLegalRight: [],
					totalComprehensiveIncome: [],
					parentCompanyTci: [],
					minorityStockTci: [],
					ociNetTax: [],
					parentCompanyOciNetTax: [],
					notReclassifiedOci: [],
					remeasureBenefitAmount: [],
					notTransferOci: [],
					otherEquityFairValue: [],
					enterpriseCreditRiskFairValue: [],
					reclassifiedOci: [],
					transferOci: [],
					otherClaimsFairValue: [],
					financialAssetsOciAmount: [],
					otherClaimsCreditImpairment: [],
					cashFlowHedgingReserve: [],
					foreignCurrencyBalance: [],
					minorityStockOciNetTax: [],
					basicEps: [],
					dilutedEps: []

				},



			}
		},
		computed: {
			initStatus: function() {
				if (this.pageInfo.baseInfo.companyName) {
					return true;
				} else {
					return false;
				}
			}
		},
		mounted() {
			this.initPage()
		},
		methods: {
			exportPDF() {
				document.getElementById("loading").style.display = "block";
				var title = this.pageInfo.baseInfo.companyName + this.typeName + '报告.pdf'
				var pageArr = document.getElementsByClassName("page");
				for (var i = 0; i < pageArr.length; i++) {
					this.pdfArr.push(pageArr[i].id)
				}
				PdfOnload.getPdf(this.pdfArr, this.pdfIndex, title);

			},
			judgingCondition: function(key, i, index) {
				if (this.profitTableInfo[key][i] === 0) {
					return true
				} else if (this.profitTableInfo[key][i] != 0 && this.profitTableInfo[key][i] != '-' && this.profitTableInfo[
						key][i]) {
					return true
				} else {
					return false
				}
			},
			initPage() {
				var that = this;
				this.$post(this.url + "valuationReport/qryDiagnosticValuationReport3", qs.stringify({
					reportId: this.$route.query.reportId ? this.$route.query.reportId : ""
				})).then(res => {
					var data = res.data;
					if (data.code == 200 && data.content) {
						that.pageInfo = data.content;
						that.pageInfo.financial = JSON.parse(that.pageInfo.financial)

						// 总资产收益率
						that.pageInfo.totalAssetsReturnOn = JSON.parse(that.pageInfo.totalAssetsReturnOn);
						that.pageInfo.totalAssetsReturnOn.title = "01、总资产收益率为" + (that.pageInfo.totalAssetsReturnOn.ratio *
							100).toFixed(2) + "%";

						// 销售费用率
						that.pageInfo.saleRatio = JSON.parse(that.pageInfo.saleRatio);
						that.pageInfo.saleRatio.title = "02、销售费用率为" + (that.pageInfo.saleRatio.ratio * 100).toFixed(2) + "%";
						// 管理费用率
						that.pageInfo.manageRatio = JSON.parse(that.pageInfo.manageRatio);
						that.pageInfo.manageRatio.title = "03、管理费用率为" + (that.pageInfo.manageRatio.ratio * 100).toFixed(2) +
							"%";
						// 研发费用率
						that.pageInfo.researchRatio = JSON.parse(that.pageInfo.researchRatio);
						that.pageInfo.researchRatio.title = "04、研发费用率为" + (that.pageInfo.researchRatio.ratio * 100).toFixed(
							2) + "%";
						// 毛利率
						that.pageInfo.grossProfitRatio = JSON.parse(that.pageInfo.grossProfitRatio);
						// that.pageInfo.grossProfitRatio.title = "04、毛利率为" + (that.pageInfo.grossProfitRatio.ratio * 100).toFixed(2) + "%";
						that.pageInfo.grossProfitRatio.title = "05、毛利率为" + (that.pageInfo.grossProfitRatio.ratio * 100)
							.toFixed(2) + "%";
						// 净利润率
						that.pageInfo.netProfiltRatio = JSON.parse(that.pageInfo.netProfiltRatio);
						// that.pageInfo.netProfiltRatio.title = "05、净利润率为" + (that.pageInfo.netProfiltRatio.ratio * 100).toFixed(2) + "%";
						that.pageInfo.netProfiltRatio.title = "06、净利润率为" + (that.pageInfo.netProfiltRatio.ratio * 100)
							.toFixed(2) + "%";
						// 主营业务收入增长率
						that.pageInfo.mainIncomeRatio = JSON.parse(that.pageInfo.mainIncomeRatio);
						that.pageInfo.mainIncomeRatio.title = "01、主营业务收入增长率为" + (that.pageInfo.mainIncomeRatio.ratio * 100)
							.toFixed(2) + "%";
						// 利润总额增长率
						that.pageInfo.totalProfitRatio = JSON.parse(that.pageInfo.totalProfitRatio);
						that.pageInfo.totalProfitRatio.title = "02、利润总额增长率为" + (that.pageInfo.totalProfitRatio.ratio * 100)
							.toFixed(2) + "%";
						// 净资产收益率
						that.pageInfo.netAssetRatio = JSON.parse(that.pageInfo.netAssetRatio);
						that.pageInfo.netAssetRatio.title = "03、净资产收益率为" + (that.pageInfo.netAssetRatio.ratio * 100).toFixed(
							2) + "%";
						// 资产负债率
						that.pageInfo.liabilitiesRatio = JSON.parse(that.pageInfo.liabilitiesRatio);
						that.pageInfo.liabilitiesRatio.title = "01、资产负债率为" + (that.pageInfo.liabilitiesRatio.ratio * 100)
							.toFixed(2) + "%";
						// 流动比率
						that.pageInfo.flowRation = JSON.parse(that.pageInfo.flowRation);
						that.pageInfo.flowRation.title = "02、流动比率为" + that.pageInfo.flowRation.ratio.toFixed(2);
						// 速动比率
						that.pageInfo.quickRatio = JSON.parse(that.pageInfo.quickRatio);
						that.pageInfo.quickRatio.title = "03、速动比率为" + that.pageInfo.quickRatio.ratio.toFixed(2);
						// EBIT利息保障倍数
						that.pageInfo.ebitMultiple = JSON.parse(that.pageInfo.ebitMultiple);
						var interestCost = JSON.parse(that.pageInfo.profitTableInfo).interestCost;
						if (interestCost[1] == "") {
							that.ebitMultipleGradeStatus = 0;
						} else {
							that.ebitMultipleGradeStatus = 1;
						}
						that.pageInfo.ebitMultiple.title = "04、EBIT利息保障倍数为" + that.pageInfo.ebitMultiple.ratio.toFixed(2);
						// 应收账款周转率
						that.pageInfo.receivablesTurnoverRatio = JSON.parse(that.pageInfo.receivablesTurnoverRatio);
						var receivables = JSON.parse(that.pageInfo.liaTableInfo).receivables;
						if (receivables[1] == "") {
							that.receivablesTurnoverRatioGradeStatus = 0;
						} else {
							that.receivablesTurnoverRatioGradeStatus = 1;
						}
						if (that.pageInfo.receivablesTurnoverRatio.batteryLevel == "0%") {
							that.pageInfo.receivablesTurnoverRatio.batteryLevel = "100%";
						}
						that.pageInfo.receivablesTurnoverRatio.title = "01、应收账款周转率为" + that.pageInfo.receivablesTurnoverRatio
							.ratio.toFixed(2);
						// 总资产周转率
						that.pageInfo.totalAssetsTurnoverRatio = JSON.parse(that.pageInfo.totalAssetsTurnoverRatio);
						that.pageInfo.totalAssetsTurnoverRatio.title = "02、总资产周转率为" + that.pageInfo.totalAssetsTurnoverRatio
							.ratio.toFixed(2);
						// 存货周转率
						that.pageInfo.inventoryTurnoverRatio = JSON.parse(that.pageInfo.inventoryTurnoverRatio);
						var inventory = JSON.parse(that.pageInfo.liaTableInfo).inventory
						if (inventory[1] == "") {
							that.inventoryTurnoverRatioGradeStatus = 0;
						} else {
							that.inventoryTurnoverRatioGradeStatus = 1;
						}
						if (that.pageInfo.inventoryTurnoverRatio.batteryLevel == "0%") {
							that.pageInfo.inventoryTurnoverRatio.batteryLevel = "100%";
						}
						that.pageInfo.inventoryTurnoverRatio.title = "03、存货周转率为" + that.pageInfo.inventoryTurnoverRatio.ratio
							.toFixed(2);

						// 杜邦分析
						if (that.pageInfo.dubangAnalysis) {
							that.pageInfo.dubangAnalysis = JSON.parse(that.pageInfo.dubangAnalysis);
						}

						// 财务状况
						that.pageInfo.financial = that.financialFormate(that.pageInfo.financial);

						// 资产负债表
						that.pageInfo.liaTableInfo = JSON.parse(that.pageInfo.liaTableInfo);
						// 利润表
						that.pageInfo.profitTableInfo = JSON.parse(this.pageInfo.profitTableInfo)
						var profitKey = Object.keys(this.profitTableInfo); //获取利润表对象的 key
						var returnProfitKey = Object.keys(this.pageInfo.profitTableInfo); //后台数据返回的资产负债表对象的 key

						profitKey.forEach((elem, index) => {
							returnProfitKey.forEach((item, i) => {
								that.pageInfo.year.forEach((yearItem, y) => {
									if (elem == item) {
										that.profitTableInfo[elem][y] = that.pageInfo.profitTableInfo[elem][y];
									} else if (!that.pageInfo.profitTableInfo[elem]) {
										if (that.pageInfo.year.length == 2) {
											that.profitTableInfo[elem] = ['', '']
										} else if (that.pageInfo.year.length == 3) {
											that.profitTableInfo[elem] = ['', '', '']
										}
									}
								})
							})
						})

						// 财务比率表
						that.financeRatio = JSON.parse(that.pageInfo.financeRatio);
						
					}

				})

			},
			// 财务状况格式化
			financialFormate(arr) { // name description value
				for (var i = 0; i < arr.length; i++) {
					if (arr[i].value == 100) {
						arr[i].marginLeft = '88px';
					} else if (arr[i].value > 80) {
						arr[i].style = "background:url(" + require('../../assets/img/growthDiagnosis/financialResult_1.png') +
							") center center no-repeat;background-size:100%;"
						arr[i].section = "良好";
					} else if (80 >= arr[i].value && arr[i].value > 60) {
						arr[i].style = "background:url(" + require('../../assets/img/growthDiagnosis/financialResult_2.png') +
							") center center no-repeat;background-size:100%;"
						arr[i].section = "较好";
					} else if (60 >= arr[i].value && arr[i].value > 40) {
						arr[i].style = "background:url(" + require('../../assets/img/growthDiagnosis/financialResult_3.png') +
							") center center no-repeat;background-size:100%;"
						arr[i].section = "一般";
					} else if (40 >= arr[i].value) {
						arr[i].style = "background:url(" + require('../../assets/img/growthDiagnosis/financialResult_4.png') +
							") center center no-repeat;background-size:100%;"
						arr[i].section = "较弱";
					}
				}
				return arr;
			}


		}


	}
</script>

<style scoped lang="less">
	/* 封面 */
	#homePage {
		overflow: hidden;
	}

	.homePage .logoImage {
		height: 96px;
		margin: 105px 0 0 107px;
	}

	.homePage p {
		font-size: 51px;
		font-weight: 400;
		color: #0478fc;
		line-height: 100%;
		text-align: center;
		margin: 180px 0 42px 0;
	}

	.homePage .homePageLine {
		width: 914px;
		height: 2px;
		background: #0478fc;
		margin: 0 auto;
	}

	.homePage .pageIndex_bg {
		width: 100%;
		height: auto;
		margin-top: -80px;
	}

	.homePage h2 {
		font-size: 90px;
		font-weight: bold;
		color: #0478fc;
		margin: 36px 0 44px 0;
		text-align: center;
		line-height: 100%;
		letter-spacing: 8px;
	}

	.homePage .reportId,
	.homePage h4 {
		font-size: 33px;
		font-weight: 400;
		line-height: 100%;
		color: #282828;
		text-align: center;
	}

	.homePage h4 {
		font-size: 30px;
		margin: 32px 0 0 0;
	}

	.homePage i {
		width: 100%;
		display: block;
		height: 21px;
		font-size: 21px;
		font-weight: 400;
		color: #282828;
		line-height: 100%;
		text-align: center;
		position: absolute;
		bottom: 59px;
	}

	/* 目录 */

	.cataloguePage h2 {
		font-size: 48px;
		font-weight: bold;
		color: #0F060B;
		margin: 60px 112px 27px 0px;
		text-align: right;
		line-height: 1;
	}

	.cataloguePage h3 {
		font-size: 28px;
		font-weight: bold;
		color: #0F060B;
		margin: 0 112px 45px 0;
		text-align: right;
		line-height: 1;
	}

	.cataloguePage .generalize>div {
		width: 1020px;
		height: 32px;
		margin: 22px auto 0px;
	}

	.cataloguePage .generalize>div p:first-child {
		font-size: 32px;
	}

	.cataloguePage .generalize p {
		float: left;
		font-size: 24px;
		font-weight: bold;
		color: #0F060B;
		line-height: 32px;
		margin-right: 11px;
	}

	.cataloguePage .generalize>div>div {
		width: 6px;
		height: 32px;
		background: #0478FC;
		float: left;
		margin: 0 28px 0 0;
	}

	.cataloguePage .titleLine {
		height: 2px;
		background: #282828;
		display: block;
		margin: 17px 110px 18px;
	}

	.cataloguePage .generalize .dottedLine {
		float: left;
		height: 2px;
		width: 736px;
		margin: 14px 20px 0;
		background: url(../../assets/img/report/catalogue_.png)left center repeat;
	}

	.cataloguePage .generalize .pageNumber {
		float: left;
		font-size: 18px;
		font-weight: 400;
		color: #282828;
		line-height: 32px;
	}

	.fontBold {
		font-size: 18px;
		font-weight: bold;
		color: #282828;
		line-height: 32px;
		float: right;
	}

	.cataloguePage .generalize ul {
		margin: 0 0 0 145px;
	}

	.cataloguePage .generalize ul li {
		height: 30px;
		margin: 9px 0 13px 0;
		width: calc(1020px - 34px);
	}

	.cataloguePage .generalize ul li p {
		font-size: 18px;
		font-weight: 400;
		color: #423838;
		line-height: 30px;
	}

	.cataloguePage .generalize ul li .dottedLine {
		width: 840px;
	}

	.cataloguePage .generalize ul.flowChart li .dottedLine {
		width: 877px;
	}

	.pageNum {
		width: 57px;
		position: absolute;
		color: #000;
		line-height: 100%;
		font-size: 22px;
		left: 50%;
		margin-left: -27px;
		bottom: 37px;
	}

	// 第一页
	.classHeading {
		font-size: 40px;
		font-weight: bold;
		color: #010000;
		line-height: 100%;
		margin-bottom: 33px;
	}

	#page-baseInfo {
		ul {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-bottom: 30px;

			li {
				width: 460px;

				span {
					font-size: 18px;
					font-weight: 400;
					color: #010000;
					line-height: 34px;
					vertical-align: top;
				}

			}
		}
	}

	#financeAnalyze_BaseInfo {
		width: 1020px;
		height: 600px;
		margin: 20px auto;
	}

	// 财务状况体检结果
	/* 财务结果 */

	.financialCondition ul.imgUl {
		margin: 40px 0 0;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.financialCondition ul.imgUl li {
		width: 450px;
		position: relative;
		margin-bottom: 15px;
	}

	.financialCondition ul.imgUl li .liDivImg {
		width: 250px;
		height: 250px;
		margin: 0 100px 36px;
		background: url(../../assets/img/growthDiagnosis/financialResult_1.png)no-repeat center center;
		background-size: 100%;
		overflow: hidden;
	}

	.financialCondition ul.imgUl li h5 {
		font-size: 24px;
		font-weight: bold;
		color: #282828;
		line-height: 100%;
		margin: 0 0 22px 0;
		text-align: center;
	}

	.financialCondition ul.imgUl li>p {
		font-size: 18px;
		font-weight: 400;
		color: #282828;
		line-height: 34px;
	}

	.financialCondition .scoreLow {
		position: absolute;
		top: 290px;
		left: 282px;
		width: 22px;
		height: 19px;
	}

	.financialCondition ul.imgUl li div>p {
		font-size: 22px;
		font-weight: bold;
		color: #282828;
		margin: 110px 0 0 94px;
		line-height: 100%;
	}

	.financialCondition ul.imgUl li div>h6 {
		font-size: 16px;
		font-weight: 400;
		color: #282828;
		line-height: 100%;
		margin: 10px 0 0 108px;
	}

	.profitStatement {
		width: 100%;
		margin-top: 47px;

		th {
			height: 45px;
			border: 1px solid #000;
			background: #0478fc;
			font-size: 18px;
			color: #fff;
		}

		td {
			height: 38px;
			border: 1px solid #000;
			font-size: 14px;
			color: #282828;
		}

		td:not(:first-child) {
			text-align: right;
			padding-right: 38px;
		}

		.total {
			// background: rgba(20, 22, 244, 0.1);
			font-weight: bold;
		}

		.trOne {
			padding-left: 40px;
			font-weight: bold;
		}

		.trTwo {
			padding-left: 70px;
		}

		.trThree {
			padding-left: 101px;
		}

		.trFour {
			padding-left: 131px;
		}

		.trFive {
			padding-left: 178px;
		}

		.trSix {
			padding-left: 116px;
		}

		.trSeven {
			padding-left: 122px;
		}
	}

	.profitStatement>tbody>tr:first-of-type>td {
		border-top: none;
	}

	.profitStatement>tbody>tr>td:first-of-type {
		text-align: left;
		padding-right: 10px;
	}
</style>