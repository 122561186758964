<template>
	<div v-if="isShow">
		<Header />
		<Carousel v-model="value" loop :height="400" arrow="never" :dots="pageSetup.bannerList.length>1?'inside':'none'">
			<CarouselItem v-for="(item,index) in pageSetup.bannerList">
				<div class="banner"
					:style="{'background':'url('+(item.imgPath?item.imgPath:setImg('news/banner','jpg'))+') center center no-repeat'}">
					<div>
						<p class="pageType">{{item.productName}}</p>
					</div>
				</div>
			</CarouselItem>
		</Carousel>
		<div class="mainContent">
			<div v-if="records.length>0">
				<ul class="news">
					<li v-for="(item,index) in records" @click="newsDetails(item.id)">
						<img :src="item.img" />
						<div>
							<p>{{item.title}}</p>
							<p>{{item.brief}}</p>
							<p>{{item.publishDate}}</p>
						</div>
					</li>
				</ul>

				<Page :current="currentPageNumber" :total="total" :page-size="pageSize" prev-text="上一页" next-text="下一页"
					@on-change="pageChange" />
			</div>
			<div v-else class="nothing">
				<img :src="setImg('nothing','png')" alt="暂无信息" />
				<p>暂无信息</p>
			</div>


		</div>


		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '../../common/header/flow_header.vue'
	import Footer from '../../common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'

	import qs from 'qs'
	import axios from 'axios'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp
		},
		data() {
			return {
				value: 0,
				pageSetup: {
					bannerList: [],
					superiority: [],
					productArchitecture: {},
					reportExample: []
				},
				records: [],
				currentPageNumber: 1, //当前页码
				total: 0, //总页数
				pageSize: 4, //每页条数

			}
		},
		mounted() {
			this.getInfo(5)
			this.findDataInformation(1)
		},
		methods: {
			pageChange(data) { //切换页数
				console.log(data)
				this.currentPageNumber = data
				this.findDataInformation(data)
			},
			findDataInformation(pageNum) {
				this.$post(this.domain.dataRealization + 'dataInformation/getDataInformationList', qs.stringify({
						pageNum: pageNum,
						pageSize: this.pageSize,
						status: 1,
					}))
					.then((res) => {
						this.records = res.data.content.records
						this.total = res.data.content.total
					});
			},
			newsDetails(id) {
				this.$router.push({
					name: 'informationDetails',
					query: {
						id: id
					}
				})
			}

		}


	}
</script>

<style scoped lang="less">
	.mainContent {
		margin-top: 75px;
	}

	.news {
		// height: 1300px;

		li {
			width: 100%;
			height: 208px;
			background: #FFFFFF;
			box-shadow: 2px 2px 13px 0px rgba(32, 76, 151, 0.23);
			border: 2px solid #FFFFFF;
			padding: 20px;
			margin-bottom: 20px;
			cursor: pointer;
			display: flex;

			img {
				width: 300px;
				height: 168px;
				object-fit: contain;
				margin-right: 20px;
			}

			>div {
				width: 830px;
				position: relative;

				p:nth-child(1) {
					width: 100%;
					font-size: 16px;
					font-weight: bold;
					color: #282828;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					margin-bottom: 10px;
				}

				p:nth-child(2) {
					font-size: 14px;
					color: #666666;
					line-height: 22px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					word-break: break-word;
					/* 允许在单词内换行，防止长单词撑破容器 */
					word-wrap: break-word;
					/* 旧的浏览器可能需要这个属性 */
				}

				p:nth-child(3) {
					font-size: 14px;
					color: #999999;
					position: absolute;
					bottom: 0;
				}

			}

		}

		li:hover {
			transform: translateY(-10px);
			-webkit-transform: translateY(-10px);
			-moz-transform: translateY(-10px);
			-ms-transform: translateY(-10px);
			-o-transform: translateY(-10px);
			transition: all .25s ease-in;
			-webkit-transition: all .25s ease-in;
			-moz-transition: all .25s ease-in;
			-ms-transition: all .25s ease-in;
			-o-transition: all .25s ease-in;

			p:nth-child(1) {
				color: var(--mainColor);
			}
		}

	}
</style>