<template>
	<div v-if="isShow">
		<Header />
		<Carousel v-model="value" loop :height="400" arrow="never" :dots="pageSetup.bannerList.length>1?'inside':'none'">
			<CarouselItem v-for="(item,index) in pageSetup.bannerList">
				<div class="banner"
					:style="{'background':'url('+(item.imgPath?item.imgPath:setImg('bp/banner','jpg'))+') center center no-repeat'}">
					<div>
						<p class="projectTitle">{{item.productName}}</p>
						<p class="banner_projectDes">{{item.describe}}</p>
					</div>
				</div>
			</CarouselItem>
		</Carousel>
		<div class="mainContent mainContentTop50">
			<div class="generateBP">
				<div>
					<p class="bpTitle">{{pageSetup.bpHeadline}}</p>
					<p>{{pageSetup.bpSubhead}}</p>
					<div class="fillCompanyName">
						<input type="text" v-model="companyName" @input="fuzzyQuery" />

						<div id="getCompanyNameList">
							<p v-for="(item,i) in getCompanyNameList" :key="i" :data-area="item.base"
								@click="checkedName(item.name,item.base)">
								{{item.name}}
							</p>
						</div>

						<div class="buttonFill creatBP" @click="creatIndustry(companyName)">立即生成BP</div>
					</div>
				</div>
				<img :src="pageSetup.bpProduct.imgPath?pageSetup.bpProduct.imgPath:setImg('bp/one','png')" />
			</div>

			<div>
				<p class="moduleTitle">{{pageSetup.videoTitle}}</p>
				<video crossorigin="anonymous" controls preload="auto" :src="pageSetup.video.url"></video>
			</div>

		</div>


		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '@/common/header/flow_header.vue'
	import Footer from '@/common/footer.vue'
	import publicMethod from '../../publicMethod'
	import SignUp from '@/components/signUp/index.vue'

	import qs from 'qs'
	import axios from 'axios'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp
		},
		data() {
			return {
				value: 0,
				companyName: '',
				getCompanyNameList: [], //模糊查询公司列表
				pageSetup: {
					bannerList: [{
						imgPath: '',
						isLoading: false,
						englishName: '', // 英文名称
						productName: '项目BP', // 产品名称
						subheading: '', // 副标题
						describe: '运用大模型和AIGC技术研发的一款创新产品。目的是提供多种人工智能工具，让广大融资者能够省时、省力、省钱地撰写出打动投资人的商业计划书（BP）。' // 文字描述
					}],
					bpHeadline: '自动生成BP',
					bpSubhead: '运用大模型和AIGC技术    一键生成打动投资人的商业计划书',
					bpProduct: {
						imgPath: '',
						isLoading: false,
						imgLoading: false
					},
					videoTitle: '一键生成打动投资人的商业计划书 支持在线修改 体验全新升级',
					video: {
						isLoading: false,
						url: ''
					}

				}

			}
		},
		mounted() {
			this.getInfo(2, 3)
		},
		methods: {



		}


	}
</script>

<style scoped lang="less">
	#getCompanyNameList {
		top: 36px;
	}

	.generateBP {
		display: flex;
		justify-content: space-between;
		align-items: center;

		>img {
			width: 558px;
			height: 367px;
			object-fit: cover;
		}

		.bpTitle {
			font-weight: bold;
			font-size: 28px;
			color: #0A1633;
		}

		.bpTitle+p {
			font-size: 16px;
			color: #282828;
			margin: 10px 0 58px;
		}

		.fillCompanyName {
			position: relative;

			input {
				width: 469px;
				height: 36px;
				background: #FFFFFF;
				font-size: 14px;
				color: #282828;
				border: 1px solid #ccc;
				margin-bottom: 30px;
				padding: 0 10px;
			}

			input:focus {
				border-color: var(--mainColor);
			}

			.creatBP {
				width: 469px;
				height: 36px;
				line-height: 36px;
				text-align: center;
				font-size: 14px;
				color: #fff;
			}

		}

	}

	video {
		width: 100%;
		height: 674px;
	}
</style>