<template>
	<div v-if="isShow">
		<Header />
		<Carousel v-model="value" loop :height="400" arrow="never" :dots="pageSetup.bannerList.length>1?'inside':'none'">
			<CarouselItem v-for="(item,index) in pageSetup.bannerList">
				<div class="banner"
					:style="{'background':'url('+(item.imgPath?item.imgPath:setImg('projectAnalysis/banner','jpg'))+') center center no-repeat'}">
					<div>
						<p class="projectTitle">{{item.productName}}</p>
						<p class="banner_projectDes">{{item.describe}}</p>
					</div>
				</div>
			</CarouselItem>
		</Carousel>
		<div class="mainContent">
			<div style="margin-top: 100px;">
				<ul v-if="pageSetup.productList.length==1" class="one_product">
					<li
						:style="{'background':'url('+(pageSetup.analyseProduct.imgPath?pageSetup.analyseProduct.imgPath:setImg('projectAnalysis/one','jpg'))+') center center no-repeat'}">
						<p class="analyseTitle">精选服务</p>
					</li>
					<li v-for="(item,i) in pageSetup.productList" :key="i"
						:style="{'background':'url('+setImg('projectAnalysis/oneDes','jpg')+') center center no-repeat'}">
						<p class="name">{{item.reportName}}</p>
						<div v-html="valuationDesHtml(item.reportStage,item.reportType)"></div>
						<div class="operate">
							<button class="buttonFill" @click="productEntrance(item.reportStage,item.reportType)">立即填写</button>
							<a class="buttonBorder" target="_blank" :href="item.reportExample">查看报告示例</a>
						</div>
					</li>
				</ul>
				<div v-else>
					<ul class="multipleProducts">
						<li :style="{'background':'url('+setImg('projectAnalysis/multi','jpg')+') center center no-repeat'}">
							<p class="analyseTitle">精选服务</p>
						</li>
						<li v-for="(item,i) in pageSetup.productList" :key="i"
							:style="{'background':'url('+setImg('projectAnalysis/two','jpg')+') center center no-repeat'}">
							<p class="name">{{item.reportName}}</p>
							<div v-html="valuationDesHtml(item.reportStage,item.reportType)"></div>
							<div class="operate">
								<button class="buttonFill" @click="productEntrance(item.reportStage,item.reportType)">立即填写</button>
								<a class="buttonBorder" target="_blank" :href="item.reportExample">查看报告示例</a>
							</div>
						</li>
					</ul>
				</div>


			</div>
		</div>



		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '@/common/header/flow_header.vue'
	import Footer from '@/common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'

	import qs from 'qs'
	import axios from 'axios'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp
		},
		data() {
			return {
				value: 0,
				pageSetup: {
					groupId: 4,
					bannerList: [{
						imgPath: '',
						isLoading: false,
						englishName: '', // 英文名称
						productName: '项目分析', // 产品名称
						subheading: '', // 副标题
						describe: '对目标公司的资产和负债情况、经营和财务情况、法律关系以及目标企业所面临的机会与潜在的风险进行的一系列调查。' // 文字描述
					}],
					analyseProduct: {
						imgPath: '',
						isLoading: false,
						imgLoading: false
					},
					productList: []


				},


			}
		},
		mounted() {
			this.getInfo(2, 4)
		},
		methods: {

		}


	}
</script>

<style scoped lang="less">
	.analyseTitle {
		font-size: 28px;
		color: #282828;
		font-weight: bold;
		position: relative;
		margin-top: 30px;
		margin-left: 30px;
		padding-bottom: 18px;
	}

	.analyseTitle::after {
		content: '';
		display: block;
		width: 50px;
		height: 5px;
		background: #000000;
		position: absolute;
		left: 0;
		bottom: 0;
	}

	.name {
		font-size: 20px;
		color: #282828;
		font-weight: bold;
		margin-bottom: 22px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.operate {
		display: flex;
		align-items: center;
		position: absolute;
		bottom: 30px;

		button,
		a {
			width: 156px;
			height: 30px;
			line-height: 30px;
			font-size: 14px;
			text-align: center;
		}

		button {
			margin-right: 14px;
		}

	}

	/deep/ .earlyWarningDes {
		p {
			margin-bottom: 15px;
		}

		span {
			font-size: 14px;
			color: #282828;
		}

		i {
			color: var(--mainColor);
			margin-right: 7px;
		}
	}

	/deep/ .dueDiligenceDes {
		width: 332px;

		>p {
			margin-bottom: 15px;

			span {
				font-size: 14px;
				color: #282828;
			}

			i {
				color: var(--mainColor);
				margin-right: 7px;
			}
		}

		>div {
			display: flex;
			flex-wrap: wrap;
			margin-left: 24px;

			span {
				width: 45%;
				font-size: 14px;
				color: #282828;
				padding-left: 16px;
				margin-bottom: 15px;
				position: relative;
			}

			span::before {
				content: '';
				display: block;
				width: 6px;
				height: 6px;
				background: var(--mainColor);
				border-radius: 50%;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}


	}



	.multipleProducts,
	.one_product {
		display: flex;
		flex-wrap: wrap;

		li {
			height: 300px;
			position: relative;
			background-size: cover !important;
			box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
			padding: 30px;
		}

		li:not(:last-child) {
			margin-right: 20px;
		}

	}

	.one_product li {
		width: 590px;
	}

	.multipleProducts li {
		width: 386px;
	}
</style>