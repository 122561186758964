<template>
	<div>
		<Header />

		<div class="ifram">
			<iframe id="iframe" ref="dynamicIframe" :src="url" width="100%" frameborder="0" height="1635"></iframe>

		</div>


		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '@/common/header/flow_header.vue'
	import Footer from '@/common/footer.vue'
	import publicMethod from '../../publicMethod'
	import SignUp from '@/components/signUp/index.vue'

	import qs from 'qs'
	import axios from 'axios'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp
		},
		data() {
			return {
				url: '',
				resources: [{
						type: 'css',
						href: 'stockBp/css/common.css'
					}, {
						type: 'css',
						href: 'stockBp/libs/css/myAlert.min.css'
					},
					{
						type: 'js',
						src: 'stockBp/libs/js/myAlert.min.js'
					},
					{
						type: 'js',
						src: 'stockBp/js/common.js'
					},
					{
						type: 'js',
						src: 'stockBp/js/iframe.js'
					}
				]


			}
		},
		mounted() {
			const now = new Date();

			this.url = this.domain.testUrl + 'stockBp/catalogue.html?id=' + window.channelId + '&v=' + now.getTime() + (
				localStorage.userToken ? '&userToken=' + localStorage.userToken : '')

			// this.url='http://127.0.0.1:5500/index.html?id=1567'+(localStorage.userToken?'&userToken='+localStorage.userToken:'')


			this.loadResources()

			sessionStorage.url = window.domainName
			sessionStorage.bpListUrl = window.domainName + '#/productList'


		},
		beforeDestroy() {
			window.location.reload();
		},
		methods: {



		}

	}
</script>

<style>
</style>