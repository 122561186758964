<template>
	<div>
		<Header />
		<Carousel loop :height="380" arrow="never" dots="none">
			<CarouselItem>
				<div class="banner">
					<div>
						<p
							style="font-size: 42px;color: var(--mainColor);font-weight: bold;text-align: center;margin-bottom: 20px;">
							恭喜完成数商测试</p>
						<p style="font-size: 36px;color: #282828;font-weight: bold;text-align: center;">TEST REPORT</p>
					</div>
				</div>
			</CarouselItem>
		</Carousel>
		<div class="mainContent">
			<div class="quotientScoreResults">
				<p>测试得分</p>
				<div>
					<div class="grade">
						<p>得分</p>
						<p>{{data.score?data.score:0}}</p>
					</div>
					<p class="remark">
						<img src="../../assets/img/numberQuotient/praise.png" />
						<span>{{data.description?data.description:''}}</span>
					</p>
				</div>
			</div>
			<p class="compare">超越了{{data.percentage.toFixed(2)}}%的用户</p>
			<p class="testTime">测试时间：{{data.createTime?data.createTime:''}}</p>
		</div>


		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '../../common/header/flow_header.vue'
	import Footer from '../../common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'

	import qs from 'qs'
	import axios from 'axios'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp
		},
		data() {
			return {
				data:{
					percentage:0
				}

			}
		},
		mounted() {
			this.getBusinessModel()
		},
		methods: {
			getBusinessModel(){
				this.$post(this.domain.dataRealization + 'businessModelReport/getBusinessModel', qs.stringify({
					businessId: this.$route.query.id
				})).then(res => {
					this.data=res.data.content
				})
				
			}

		}


	}
</script>

<style scoped lang="less">
	.banner {
		background: url(../../assets/img/numberQuotient/testResultsBanner.png) center center no-repeat;
	}
	
	body[hue='3'] {
		.banner {
			background: url(../../assets/img/numberQuotient/testResultsBannerRed.jpg) center center no-repeat;
		}
	}

	.mainContent {
		box-shadow: 0px 0px 13px 0px rgba(98, 136, 200, 0.23);
		padding: 50px 0;
		margin-top: 75px;
		
		.compare{
			font-size: 20px;
			color: #C9312D;
			text-align: center;
			margin-top: 10px;
			margin-bottom: 15px;
		}
		.testTime{
			font-size: 16px;
			color: #282828;
			text-align: center;
		}

		.quotientScoreResults {
			>p {
				font-size: 28px;
				font-weight: bold;
				color: #282828;
				text-align: center;
				margin-bottom: 29px;
			}

			>div {
				width: 728px;
				height: 774px;
				background: url(../../assets/img/numberQuotient/score.png) center center no-repeat;
				background-size: cover;
				margin: 0 auto;
				position: relative;

				.grade {
					position: relative;
					top: 444px;
					text-align: center;
					
					p:first-child{
						font-size: 36px;
						color: #fff;
					}
					p:last-child{
						font-size: 120px;
						color: #fff;
						position: relative;
						top: -20px;
					}
				}
				
				.remark{
					width: 350px;
					height: 83px;
					position: absolute;
					left: 50%;
					bottom: 75px;
					transform: translateX(-50%);
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 0 auto;
					padding: 0 37px;
					
					span{
						margin-left: 10px;
						font-size: 20px;
						font-weight: bold;
						color: #1459F4;
						text-align: justify;
					}
				}

			}

		}

	}
</style>