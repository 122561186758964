import { render, staticRenderFns } from "./data.vue?vue&type=template&id=405059ee&scoped=true"
import script from "./data.vue?vue&type=script&lang=js"
export * from "./data.vue?vue&type=script&lang=js"
import style0 from "./data.vue?vue&type=style&index=0&id=405059ee&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "405059ee",
  null
  
)

export default component.exports