<template>
	<div>
		<div v-if="records.length>0">
			<ul class="boxhead">
				<li style="width: 30%;">企业名称</li>
				<li style="width: 25%;">所属行业</li>
				<li style="width: 17%;">{{type=='-1'?'估值时间':'测评时间'}}</li>
				<li style="width: 13%;" v-if="type=='-1'">类型</li>
				<li style="width: 14%;">状态</li>
				<li style="width: 15%;">操作</li>
			</ul>

			<ul class="dataList">
				<li v-for="(item,index) in records" :key="index">
					<div style="width: 30%;">{{item.name?item.name:''}}</div>
					<div style="width: 25%;">{{item.industryName ? item.industryName : ''}}</div>
					<div style="width: 17%;">{{item.createTime}}</div>
					<div style="width: 13%;" v-if="type=='-1'">{{item.type=='13'?'数据专业估值':'数据快速估值'}}</div>
					<div style="width: 14%;">
						<div v-if="item.payStatus==1">
							<p class="passAudit" v-if="item.secondAuditStatus==1">
								<img src="../../assets/img/myCenter/status1.png" />
								<span>审核通过</span>
							</p>
							<p class="modifications" v-else-if="item.secondAuditStatus==2">
								<img src="../../assets/img/myCenter/status3.png" />
								<span>审核中</span>
							</p>
							<p class="failAudit" v-else>
								<img src="../../assets/img/myCenter/status2.png" />
								<span>审核不通过</span>
							</p>
						</div>
						<p class="examines" v-else>
							<img src="../../assets/img/myCenter/status5.png" />
							<span>待提交</span>
						</p>
					</div>
					<div style="width: 15%;">
						<button class="modification" style="margin-bottom: 0;"
							v-if="item.secondAuditStatus!=1&&item.secondAuditStatus!=2" @click="amend(item.id,item.type)">修改</button>
						<button class="delete" v-if="item.payStatus!=1" @click="deleteProduct(item.id)">删除</button>
						<button class="examine" v-if="item.payStatus==1" @click="getInfo(index,item.type)">查看信息</button>
						<button class="examine" v-if="item.secondAuditStatus==1"
							@click="viewReport(item.id,item.type)">查看报告</button>
					</div>
				</li>
			</ul>

			<Page :current="parameter.pageNum" :total="total" :page-size="parameter.pageSize" prev-text="上一页" next-text="下一页"
				@on-change="pageChange" />


		</div>
		<div v-else class="nothing">
			<img :src="setImg('nothing','png')" alt="" />
			<p>暂无信息</p>
		</div>

		<Modal v-model="myAlert" class-name="vertical-center-modal del" @on-ok="ok()">
			<img class='warning' src="../../assets/img/remind-01@2x.png">
			<div>
				<p>确定要删除吗？</p>
				<p>删除后无法恢复这条记录</p>
			</div>
		</Modal>


	</div>
</template>

<script>
	import qs from 'qs'
	import axios from 'axios'
	import publicMethod from '@/views/publicMethod'

	export default {
		mixins: [publicMethod],
		props: {
			type: {
				type: String,
				required: true
			},
			listData: {
				type: Array,
				required: true
			},
			parameter: {
				type: Object,
				required: true
			},
			total: {
				type: Number,
				required: true
			}

		},
		data() {
			return {
				myAlert: false,
				delId: '',
				records: []

			}
		},
		watch: {
			listData: {
				handler: function(newValue, oldValue) {
					this.$set(this, 'records', newValue)
				},
				deep: true
			}

		},
		mounted() {

		},
		methods: {
			pageChange(data) { //切换页数
				console.log(data)
				this.parameter.pageNum = data
				this.findDataProduct()
			},
			findDataProduct() {
				this.$post(this.domain.evaluation + 'valuationReport/getDataValuationListByCustomerPage', qs.stringify(this
						.parameter))
					.then((res) => {
						this.records = res.data.content.records
						this.total = res.data.content.total
					});
			},
			amend(id, type) { //修改
				this.$router.push({
					path: type == 13 ? 'dataInfoSpecialty' : type == 15 ? 'dataValuationInfoFast' :
						'basicInformationEvaluation',
					query: {
						type: type,
						reportId: id
					}
				})
			},
			deleteProduct(id) { //删除
				this.myAlert = true
				this.delId = id
			},
			ok() {
				this.$post(this.domain.evaluation + 'valuationReport/deleteValuationReportById', qs.stringify({
						reportId: this.delId
					}))
					.then((res) => {
						this.findDataProduct()
					});
			},
			viewReport(id, type) { //查看报告
				let routeData = this.$router.resolve({
					name: type == 13 ? 'dataReportSpecialty' : type == 15 ? 'dataValuationReportFast' : 'reportEvaluation',
					query: {
						reportId: id,
					}
				})
				window.open(routeData.href, '_blank');
			},
			getInfo(i, type) { //查看信息
				if (this.records[i].secondAuditStatus == 1) {
					this.$router.push({
						name: type == 13 ? 'memberbasicTitle' : type == 15 ? 'dataValuationEInfoFast' : 'examineInformation',
						query: {
							type: this.records[i].type,
							reportId: this.records[i].id,
							auditStatus: this.records[i].secondAuditStatus
						}
					})
				} else {
					this.$router.push({
						name: type == 13 ? 'dataAuditSpecialty' : type == 15 ? 'dataValuationAuditFast' : 'examineAuditStatus',
						query: {
							type: this.records[i].type,
							reportId: this.records[i].id,
							auditStatus: this.records[i].secondAuditStatus
						}
					})

				}



			}



		}

	}
</script>

<style>
</style>