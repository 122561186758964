<template>
	<div id="main">
		<Header />
		<Banner />

		<div class="mainContent">
			<MyCenterMenu ref="menu" :activeName="$route.meta.name" @getName="getName" />

			<div class="userinfo">
				<Search :titleInfo="searchTitle" :url="domain.dataRealization+'productOrder/getProductOrderList'"
					:parameter="parameter" :allStatus="allStatus" @getList="getList" />

				<div v-if="records.length>0">
					<ul class="boxhead">
						<li style="width: 20%;">订单号</li>
						<li style="width: 17%;">数据产品名称</li>
						<li style="width: 12%;">下单账号</li>
						<li style="width: 11%;">下单时间</li>
						<li style="width: 5%;">数量</li>
						<li style="width: 13%;">价格</li>
						<li style="width: 12%;">订单状态</li>
						<li style="width: 10%;">操作</li>
					</ul>
					<ul class="dataList">
						<li v-for="(item,index) in records" :key="index">
							<div style="width: 20%;">{{item.id}}</div>
							<div style="width: 17%;">{{item.productName}}</div>
							<div style="width: 12%;">{{item.orderPhone.slice(0,3)+'****'+item.orderPhone.slice(7)}}</div>
							<div style="width: 11%;line-height: 22px;">
								<span
									style="position: relative;top: 7px;text-align: left;">{{item.createTime.slice(0,11)}}</br>{{item.createTime.slice(11)}}</span>
							</div>
							<div style="width: 5%;">x{{item.num}}</div>
							<div style="width: 13%;">{{item.discuss?'面议':'￥'+method.formateNum(item.price,2)+'/次'}}</div>
							<div style="width: 12%;">
								<button class="waitConfirmation" v-if="item.orderType==0">等待确认</button>
								<button class="confirmed" v-if="item.orderType==1">已确认</button>
								<button class="completed" v-if="item.orderType==2">已完成</button>
								<button class="fackOf" v-if="item.orderType==3">交易关闭</button>
							</div>
							<div style="width: 10%;">
								<div v-if="item.orderType==0" style="line-height: 25px;text-align: left;">
									<p class="tradingOperation" @click="checkOrder(item.id)">
										<img src="../../../assets/img/myCenter/check.png" alt="查看" />
										<span>查看</span>
									</p>
									<p class="tradingOperation" @click="confirmOrder(item.id,index)">
										<img src="../../../assets/img/myCenter/affirm.png" alt="确认订单" />
										<span>确认订单</span>
									</p>
								</div>
								<p class="tradingOperation" v-else @click="checkOrder(item.id)">
									<img src="../../../assets/img/myCenter/check.png" alt="查看" />
									<span>查看</span>
								</p>
							</div>
						</li>
					</ul>

					<Page :current="currentPageNumber" :total="total" :page-size="pageSize" prev-text="上一页" next-text="下一页"
						@on-change="pageChange" />

				</div>
				<div v-else class="nothing">
					<img :src="setImg('nothing','png')" alt="暂无信息" />
					<p>暂无信息</p>
				</div>


			</div>
		</div>


		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '@/common/header/flow_header.vue'
	import Footer from '@/common/footer.vue'
	import publicMethod from '@/views/publicMethod'
	import SignUp from '@/components/signUp/index.vue'
	import MyCenterMenu from '@/components/myCenterMenu/index.vue'
	import Banner from '@/views/myCenter/banner.vue'
	import Search from '@/components/listSearch/index.vue'
	import qs from 'qs'
	import axios from 'axios'

	import '../personalCenter.less'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp,
			MyCenterMenu,
			Banner,
			Search,
		},
		data() {
			return {
				searchTitle: {},
				name: '',
				records: [],
				currentPageNumber: 1, //当前页码
				total: 0, //总页数
				pageSize: 5, //每页条数
				status: '',
				parameter: {
					pageNum: 1,
					pageSize: 5,
					status: '',
					name: '',
					orderFlag: 2 //（买家：1，卖家：2）
				},
				allStatus: [{ //订单状态  0：等待确认，1：已确认，2：已完成，3：已取消（交易关闭）
					state: '全部状态',
					statusCode: ''
				}, {
					state: '等待确认',
					statusCode: 0
				}, {
					state: '已确认',
					statusCode: 1
				}, {
					state: '已完成',
					statusCode: 2
				}, {
					state: '交易关闭',
					statusCode: 3
				}],
				myAlert: false,

			}
		},
		mounted() {
			if (this.$route.params.num) {
				this.currentPageNumber = Number(this.$route.params.num)
			}

			this.findDataProduct(this.currentPageNumber)
		},
		methods: {
			getName(data) { //获取搜索栏的信息
				console.log(data)
				this.searchTitle = data
			},
			getList(data) { //获取列表信息
				this.records = data.records
				this.name = data.name
				this.status = data.status
				this.total = data.total
			},
			pageChange(data) { //切换页数
				console.log(data)
				this.currentPageNumber = data
				this.findDataProduct(data)
			},
			findDataProduct(pageNum) { //订单状态  0：等待确认，1：已确认，2：已完成，3：已取消（交易关闭）
				this.$post(this.domain.dataRealization + 'productOrder/getProductOrderList', qs.stringify({
						pageNum: pageNum,
						pageSize: this.pageSize,
						status: this.status,
						name: this.name,
						orderFlag: 0, //1:买家 0：卖家
						channelFlag: '0' // 0:个人，1：渠道
					}))
					.then((res) => {
						this.records = res.data.content.records
						this.total = res.data.content.total
					});
			},
			confirmOrder(id, i) { //确认订单
				this.$post(this.domain.dataRealization + 'productOrder/updateProductOrder', qs.stringify({
						orderId: id,
						orderType: 1
					}))
					.then((res) => {
						if (res.data.code == 200) {
							this.$Message.success('操作成功')
							this.records[i].orderType = 1
						}
					});
			},
			checkOrder(id) {
				this.$router.push({
					name: 'tradingParticulars',
					query: {
						id: id + '-' + this.currentPageNumber
					}
				})
			}

		}
	}
</script>

<style scoped lang="less">
	.mainContent {
		margin-top: 75px;
		display: flex;
		justify-content: space-between;
	}
</style>