<template>
	<div>
		<Header />
		<Carousel loop :height="380" arrow="never" dots="none">
			<CarouselItem>
				<div class="banner">
					<div>
						<p
							style="font-size: 42px;color: var(--mainColor);font-weight: bold;text-align: center;margin-bottom: 20px;">
							恭喜完成数字化企业商业模式测评</p>
						<p style="font-size: 36px;color: #282828;font-weight: bold;text-align: center;">TEST REPORT</p>
					</div>
				</div>
			</CarouselItem>
		</Carousel>
		<div class="mainContent">
			<div>
				<p class="moduleTitle">你企业的组合式颠覆商业模式</p>
				<ul class="businessModel" v-if="modelEvaluationResult.length>0">
					<li v-for="(item,index) in modelEvaluationResult">
						<p>{{item.content.substring(0,4)}}</p>
						<img v-if="$store.state.user.subjectColor.hue=='3'"
							:src="require('@/assets/img/numberQuotient/businessModelRed'+(index+1)+'.jpg')" />
						<img v-else :src="require('@/assets/img/numberQuotient/businessModel'+(index+1)+'.png')" />
						<div>
							<span v-for="(answer, a) in list[index].title">
								<i v-if="list[index].title.length>1&&a!=list[index].title.length-1">{{answer}}、</i>
								<i v-else>{{answer}}</i>
							</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="costValue" v-if="modelEvaluationResult.length>0">
			<div :style="{'background':'url('+setImg('numberQuotient/side','png')+') center center no-repeat'}">
				<div>
					<p style="font-size: 28px;font-weight: bold;color: #fff;margin-bottom: 15px;">成本价值</p>
					<p style="font-size: 20px;color: #fff;line-height: 34px;">以向客户提供更低成本或其他</br>经济收益为竞争手段</p>
				</div>
			</div>
			<div class="testResult">
				<ul>
					<li v-for="(item,index) in modelEvaluationResult[0].options" v-if="item.chooseFlag==1">
						<div>
							<p>{{getSubstring(item.content)}}</p>
							<p>{{getSubstringValue(item.content)}}</p>
						</div>
						<p :style="{'background':'url('+setImg('numberQuotient/arrows','png')+') center center no-repeat'}"></p>
						<div>
							<p>客户价值</p>
							<p>{{item.customerValue}}</p>
						</div>
					</li>
				</ul>

			</div>
		</div>

		<div class="experienceValue" v-if="modelEvaluationResult.length>0">
			<div>
				<p class="moduleTitle" style="margin-top: 0;margin-bottom: 15px;">体验价值</p>
				<p style="font-size: 20px;color: #282828;text-align: center;">向客户提供出色的体验为竞争手段</p>
				<div class="testResult" style="width: 810px;margin: 29px auto 0;">
					<ul>
						<li v-for="(item,index) in modelEvaluationResult[1].options" v-if="item.chooseFlag==1">
							<div>
								<p>{{getSubstring(item.content)}}</p>
								<p>{{getSubstringValue(item.content)}}</p>
							</div>
							<p :style="{'background':'url('+setImg('numberQuotient/arrows','png')+') center center no-repeat'}"></p>
							<div>
								<p>客户价值</p>
								<p>{{item.customerValue}}</p>
							</div>
						</li>
					</ul>

				</div>
			</div>
		</div>

		<div class="costValue platformValue" v-if="modelEvaluationResult.length>0">
			<div :style="{'background':'url('+setImg('numberQuotient/side','png')+') center center no-repeat'}">
				<div>
					<p style="font-size: 28px;font-weight: bold;color: #fff;margin-bottom: 15px;">平台价值</p>
					<p style="font-size: 20px;color: #fff;line-height: 34px;">以向客户提供正面的网络效应</br>为竞争手段</p>
				</div>
			</div>
			<div class="testResult">
				<ul>
					<li v-for="(item,index) in modelEvaluationResult[2].options" v-if="item.chooseFlag==1">
						<div>
							<p>{{getSubstring(item.content)}}</p>
							<p>{{getSubstringValue(item.content)}}</p>
						</div>
						<p :style="{'background':'url('+setImg('numberQuotient/arrows','png')+') center center no-repeat'}"></p>
						<div>
							<p>客户价值</p>
							<p>{{item.customerValue}}</p>
						</div>
					</li>
				</ul>

			</div>
		</div>


		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '../../common/header/flow_header.vue'
	import Footer from '../../common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'

	import qs from 'qs'
	import axios from 'axios'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp
		},
		data() {
			return {
				modelEvaluationResult: [],
				list: [{
					title: []
				}, {
					title: []
				}, {
					title: []
				}]


			}
		},
		mounted() {
			this.getDigitalBusiness()
		},
		methods: {
			getSubstring(str) {
				const regex = /、(.*?)：/;
				const match = str.match(regex);
				return match ? match[1] : '';
			},
			getSubstringValue(str) {
				// 找到 `:` 的位置
				let colonIndex = str.indexOf("：");
				// 截取 `:` 后面的内容
				let result = str.substring(colonIndex + 1).trim();
				return result
			},
			getDigitalBusiness() {
				this.$post(this.domain.dataRealization + 'digitalBusiness/getDigitalBusiness', qs.stringify({
						businessId: this.$route.query.id ? this.$route.query.id : ''
					}))
					.then((res) => {
						this.modelEvaluationResult = res.data.content

						res.data.content.map((item, i) => {
							item.options.map((answer, a) => {
								if (answer.chooseFlag == 1) {
									this.list[i].title.push(this.getSubstring(answer.content))
								}
							})
						})

						console.log(this.list)

					});
			},

		}


	}
</script>

<style scoped lang="less">
	.banner {
		background: url(../../assets/img/numberQuotient/testResultsBanner.png) center center no-repeat;
	}

	body[hue='3'] {
		.banner {
			background: url(../../assets/img/numberQuotient/testResultsBannerRed.jpg) center center no-repeat;
		}
	}

	.businessModel {
		display: flex;
		justify-content: space-between;

		li {
			width: 381px;

			>p {
				height: 50px;
				line-height: 50px;
				background: var(--mainColor);
				text-align: center;
				font-size: 20px;
				font-weight: bold;
				color: #ffff;
			}

			>img {
				height: 223px;
				display: block;
			}

			>div {
				width: 100%;
				height: 110px;
				background: #FFFFFF;
				box-shadow: 2px 2px 13px 0px rgba(32, 76, 151, 0.23);
				padding: 20px 32px;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				align-items: center;


				span {
					// width: 130px;
					font-size: 18px;
					font-weight: bold;
					color: #282828;
					// display: block;
					text-align: left;

					i {
						font-size: 18px;
						font-weight: bold;
						color: #282828;
					}
				}

				span:nth-child(3),
				span:nth-child(4),
				span:nth-child(5) {
					// margin-top: 10px;
				}

				span:nth-child(even) {
					// margin-left: 10px;
				}
			}

		}
	}

	.costValue {
		width: 100%;
		min-height: 300px;
		background: url(../../assets/img/numberQuotient/costValue.png) center center no-repeat;
		background-size: cover !important;
		margin: 75px 0;
		position: relative;
		display: flex;

		>div:first-child {
			width: 38%;
			background-size: cover !important;
			padding: 40px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;

			>div {
				width: 268px;
			}

			p {
				width: 100%;
			}

		}

		.testResult {
			width: 62%;
			padding: 40px 0;
			// background: pink;
			position: relative;
			right: 50px;
		}


	}

	.testResult {
		display: flex;
		align-items: center;

		ul {
			width: 100%;

			li:last-child {
				margin-bottom: 0;
			}

			li {
				width: 100%;
				display: flex;
				align-items: center;
				margin-bottom: 30px;

				>div {
					width: 420px;
					height: 138px;
					background: #F4F7FA;
					box-shadow: 2px 2px 13px 0px rgba(32, 76, 151, 0.23);
					border-radius: 10px;
					border: 2px solid #FFFFFF;
					padding: 0 19px;

					>p:first-child {
						font-weight: bold;
						font-size: 20px;
						color: #282828;
						margin-bottom: 10px;
						margin-top: 20px;
					}

					>p:last-child {
						font-size: 14px;
						color: #282828;
						line-height: 22px;
						text-align: justify;
					}

				}

				>div:last-child {
					width: 340px;
				}


				>p {
					width: 52px;
					height: 18px;
					margin-right: 10px;
				}

			}

		}
	}

	.experienceValue {
		width: 100%;
		min-height: 348px;
		background: url(../../assets/img/numberQuotient/experienceValue.jpg) center center no-repeat;
		background-size: cover;
		margin: 76px 0;
		padding: 46px 0;

		>div {
			width: 84%;
		}
	}

	.platformValue {
		background: url(../../assets/img/numberQuotient/platformValue.png) center center no-repeat;
	}


	body[hue='3'] {
		.costValue {
			background: url(../../assets/img/numberQuotient/costValueRed.jpg) center center no-repeat;
		}

		.experienceValue {
			background: url(../../assets/img/numberQuotient/experienceValueRed.jpg) center center no-repeat;
		}

		.platformValue {
			background: url(../../assets/img/numberQuotient/platformValueRed.jpg) center center no-repeat;
		}
	}
</style>