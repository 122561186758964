<template>
	<div v-if="isShow">
		<Header />
		<Carousel v-model="value" loop :height="400" arrow="never" :dots="pageSetup.bannerList.length>1?'inside':'none'">
			<CarouselItem v-for="(item,index) in pageSetup.bannerList">
				<div class="banner"
					:style="{'background':'url('+(item.imgPath?item.imgPath:setImg('secondaryPage/diagnose/banner','jpg'))+') center center no-repeat'}">
					<div>
						<p class="projectTitle">{{item.productName}}</p>
						<p class="banner_projectDes">{{item.describe}}</p>
					</div>
				</div>
			</CarouselItem>
		</Carousel>
		<div class="mainContent">
			<div>
				<p class="moduleTitle">精选服务</p>
				<div v-if="pageSetup.productList.length==1" class="one_product">
					<div>
						<img
							:src="pageSetup.diagnoseProduct.imgPath?pageSetup.diagnoseProduct.imgPath:setImg('secondaryPage/diagnose/one','jpg')" />
					</div>
					<div>
						<p class="name">{{pageSetup.productList[0].reportName}}</p>
						<div v-html="diagnoseDesHtml(pageSetup.productList[0].reportStage)"></div>

						<div class="operate">
							<button
								@click="productEntrance(pageSetup.productList[0].reportStage,pageSetup.productList[0].reportType)">立即诊断</button>
							<a target="_blank" :href="pageSetup.productList[0].reportExample">查看报告示例</a>
						</div>
					</div>
				</div>
				<div v-else-if="pageSetup.productList.length==3">
					<ul class="multipleProducts threeProducts">
						<li v-for="(item,i) in pageSetup.productList" :key="i"
							:style="{'background':'url('+setImg('secondaryPage/diagnose/three'+(i+1),'jpg')+') center center no-repeat'}">
							<p class="name">{{item.reportName}}</p>
							<div v-html="diagnoseDesHtml(item.reportStage)"></div>
							<div class="operate">
								<button @click="productEntrance(item.reportStage,item.reportType)">立即诊断</button>
								<a target="_blank" :href="item.reportExample">查看报告示例</a>
							</div>
						</li>
					</ul>

				</div>
				<div v-else>
					<ul class="multipleProducts">
						<li v-for="(item,i) in pageSetup.productList" :key="i"
							:style="{'width': '590px','height': '309px','background':'url('+setImg('secondaryPage/diagnose/four'+(i+1),'jpg')+') center center no-repeat'}">
							<p class="name">{{item.reportName}}</p>
							<div v-html="diagnoseDesHtml(item.reportStage)"></div>
							<div class="operate">
								<button class="buttonFill" @click="productEntrance(item.reportStage,item.reportType)">立即诊断</button>
								<a class="buttonBorder" target="_blank" :href="item.reportExample">查看报告示例</a>
							</div>
						</li>
					</ul>
				</div>


			</div>
		</div>

		<div class="why">
			<p class="moduleTitle">企业为什么要诊断？</p>
			<img :src="pageSetup.whyDiagnose.imgPath?pageSetup.whyDiagnose.imgPath:setImg('secondaryPage/diagnose/why','jpg')"
				alt="企业为什么要诊断" />
		</div>


		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '@/common/header/flow_header.vue'
	import Footer from '@/common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'

	import qs from 'qs'
	import axios from 'axios'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp
		},
		data() {
			return {
				value: 0,
				pageSetup: {
					bannerList: [{
						imgPath: '',
						isLoading: false,
						englishName: '', // 英文名称
						productName: '项目诊断', // 产品名称
						subheading: '', // 副标题
						describe: '企业健康程度的体检报告，综合考虑外部环境及自身因素对企业价值的影响，从行业、团队、产品、技术、风控、财务六个维度形成评价指标体系，对企业经营管理进行综合诊断，提出专业发展建议。' // 文字描述
					}],
					diagnoseProduct: {
						imgPath: 'http://tdm-admin.flcccc.com/img/cover6.e7c30c86.jpg',
						isLoading: false,
						imgLoading: false
					},
					whyDiagnose: { // 企业为什么要估值
						imgPath: 'http://tdm-admin.flcccc.com/img/cover6.e7c30c86.jpg',
						isLoading: false,
						imgLoading: false
					},
					productList: [{
							reportName: '初创期企业诊断',
							reportStage: '1',
							reportType: '7',
							reportExample: 'https://test.flcccc.com/flow/upload/stockSetting/pdf/startupReportExample.pdf'
						},
						{
							reportName: '成长期企业诊断',
							reportStage: '2',
							reportType: '7',
							reportExample: ''
						},
						{
							reportName: '成熟期企业诊断',
							reportStage: '3',
							reportType: '7',
							reportExample: ''
						},
						{
							reportName: '专精特新企业诊断',
							reportStage: '7',
							reportType: '7',
							reportExample: ''
						}
					]

				},


			}
		},
		mounted() {
			// this.isShow = true
			this.getInfo(2, 2)
		},
		methods: {

		}


	}
</script>

<style scoped lang="less">
	.name {
		font-size: 20px;
		font-weight: bold;
		margin-bottom: 12px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.operate {
		display: flex;
		align-items: center;
	}

	.one_product {
		display: flex;
		align-items: center;

		>div:first-child {
			width: 669px;
			height: 395px;
			box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		>div:nth-child(2) {
			width: 531px;
			height: 335px;
			background: var(--mainColor);
			padding: 40px 50px;
			color: #fff;
		}

		button,
		a {
			width: 200px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			outline: none;
			margin-top: 12px;
			border: 1px solid #fff;
			cursor: pointer;
			display: inline-block;
		}

		button:nth-child(1) {
			margin-right: 16px;
			background: #fff;
			color: var(--mainColor);
		}

		a {
			background: var(--mainColor);
			color: #fff;
		}

		button:hover,
		a:hover {
			text-decoration: underline !important;
		}

	}

	/deep/ .desTitle {
		margin-bottom: 12px;

		i {
			margin-right: 8px;
		}

		span {
			font-size: 16px;
		}
	}

	/deep/ .introduce {
		width: 275px;
		display: flex;
		flex-wrap: wrap;

		li {
			width: 135px;
			margin-bottom: 12px;

			i {
				margin-right: 8px;
			}

			span {
				font-size: 14px;
			}
		}

		li:nth-child(odd) {
			margin-right: 5px;
		}

	}

	.threeProducts {
		>li {
			width: 386px;
			height: 312px;
		}

		>li:nth-child(-n+2) {
			margin-right: 20px;
		}

		.operate {
			width: 100%;
			position: absolute;
			left: 0;
			bottom: 0;
			border-top: 1px solid #E0E0E0;

			button,
			a {
				width: 50%;
				height: 42px;
				line-height: 42px;
				text-align: center;
				font-size: 14px;
				color: var(--mainColor);
				outline: none;
				background: transparent;
				cursor: pointer;
			}

			button {
				border-right: 1px solid #E0E0E0;
			}

			button:hover,
			a:hover {
				color: var(--complementaryColors);
				text-decoration: underline !important;
			}

		}

	}

	/deep/ .multipleProducts:not(.threeProducts) {
		>li:nth-child(odd) {
			margin-right: 20px;
		}

		button,
		a {
			width: 160px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			outline: none;
			margin-top: 12px;
			border: 1px solid var(--mainColor);
			cursor: pointer;
			display: inline-block;
		}

		button:nth-child(1) {
			margin-right: 16px;
		}

	}

	/deep/ .multipleProducts {
		display: flex;
		flex-wrap: wrap;

		.name {
			color: #282828;
		}

		span {
			color: #282828;
		}

		i {
			color: var(--mainColor);
			font-size: 18px;
		}

		>li {
			position: relative;
			background: #FFFFFF;
			background-size: cover !important;
			box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
			margin-bottom: 20px;
			padding: 30px;
		}

	}

	.why {
		margin-bottom: 75px;

		img {
			width: 100%;
			height: 420px;
			object-fit: cover;
		}
	}
</style>