<template>
	<div v-if="isShow">
		<Header />
		<Carousel v-model="value" loop :height="400" arrow="never" :dots="pageSetup.bannerList.length>1?'inside':'none'">
			<CarouselItem v-for="(item,index) in pageSetup.bannerList">
				<div class="banner"
					:style="{'background':'url('+(item.imgPath?item.imgPath:setImg('secondaryPage/valuation/banner','jpg'))+') center center no-repeat'}">
					<div>
						<p class="projectTitle">{{item.productName}}</p>
						<p class="banner_projectDes" v-if="item.describe.indexOf('|')==-1">{{item.describe}}</p>
						<p class="banner_explain" v-else v-for="(des,d) in item.describe.split('|').slice(0,4)"
							style="margin-bottom: 13px;">
							{{des}}
						</p>

					</div>
				</div>
			</CarouselItem>
		</Carousel>
		<div class="mainContent">
			<div>
				<p class="moduleTitle">精选服务</p>
				<div v-if="pageSetup.productList.length==1" class="one_product">
					<div>
						<img
							:src="pageSetup.valuationProduct.imgPath?pageSetup.valuationProduct.imgPath:setImg('secondaryPage/valuation/one','jpg')" />
					</div>
					<div class="introduce">
						<p class="name">{{pageSetup.productList[0].reportName}}</p>
						<div v-html="valuationDesHtml(pageSetup.productList[0].reportStage)"></div>

						<div class="operate">
							<button
								@click="productEntrance(pageSetup.productList[0].reportStage,pageSetup.productList[0].reportType)">立即估值</button>
							<a target="_blank" :href="pageSetup.productList[0].reportExample">查看报告示例</a>
						</div>
					</div>
				</div>
				<div v-else-if="pageSetup.productList.length==3">
					<ul class="multipleProducts threeProducts">
						<li v-for="(item,i) in pageSetup.productList" :key="i">
							<p class="name"
								:style="{'background':'url('+setImg('secondaryPage/valuation/three'+(i+1),'jpg')+') center center no-repeat'}">
								{{item.reportName}}
							</p>
							<div v-html="valuationDesHtml(item.reportStage)"></div>
							<div class="operate">
								<button class="buttonFill" @click="productEntrance(item.reportStage,item.reportType)">立即估值</button>
								<a class="buttonBorder" target="_blank" :href="item.reportExample">查看报告示例</a>
							</div>
						</li>
					</ul>

				</div>
				<div v-else>
					<ul class="multipleProducts">
						<li v-for="(item,i) in pageSetup.productList" :key="i"
							:style="{'background':'url('+setImg('secondaryPage/valuation/seven'+(i+1),'jpg')+') center center no-repeat'}">
							<p class="name">{{item.reportName}}</p>
							<div v-html="valuationDesHtml(item.reportStage)"></div>
							<div class="operate">
								<button class="buttonFill" @click="productEntrance(item.reportStage,item.reportType)">立即估值</button>
								<a class="buttonBorder" target="_blank" :href="item.reportExample">查看报告示例</a>
							</div>
						</li>
					</ul>
				</div>

			</div>
		</div>

		<div class="why">
			<p class="moduleTitle">企业为什么要估值？</p>
			<img
				:src="pageSetup.whyValuation.imgPath?pageSetup.whyValuation.imgPath:setImg('secondaryPage/valuation/why','jpg')"
				alt="企业为什么要估值" style="height: 420px;" />
		</div>

		<div class="valueModel">
			<p class="moduleTitle">七维价值模型</p>
			<img
				:src="pageSetup.sevenDimensionalValue.imgPath?pageSetup.sevenDimensionalValue.imgPath:setImg('secondaryPage/valuation/model','jpg')"
				alt="七维价值模型" />
		</div>

		<div class="why">
			<p class="moduleTitle">专业的估值方法</p>
			<img
				:src="pageSetup.valuationMethod.imgPath?pageSetup.valuationMethod.imgPath:setImg('secondaryPage/valuation/method','jpg')"
				alt="专业的估值方法" style="height: 430px;" />
		</div>

		<div class="targetSystem">
			<p class="moduleTitle">指标体系</p>
			<div>
				<img
					:src="pageSetup.targetSystem.imgPath?pageSetup.targetSystem.imgPath:setImg('secondaryPage/valuation/target','jpg')"
					alt="指标体系" />
			</div>
		</div>


		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '@/common/header/flow_header.vue'
	import Footer from '@/common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'

	import qs from 'qs'
	import axios from 'axios'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp
		},
		data() {
			return {
				value: 0,
				pageSetup: {
					groupId: 1,
					bannerList: [{
						imgPath: '',
						isLoading: false,
						englishName: '', // 英文名称
						productName: '项目估值', // 产品名称
						subheading: '', // 副标题
						describe: '' // 文字描述
					}],
					valuationProduct: {
						imgPath: 'http://tdm-admin.flcccc.com/img/cover6.e7c30c86.jpg',
						isLoading: false,
						imgLoading: false
					},
					whyValuation: { // 企业为什么要估值
						imgPath: 'http://tdm-admin.flcccc.com/img/cover6.e7c30c86.jpg',
						isLoading: false,
						imgLoading: false
					},
					sevenDimensionalValue: { // 七维价值模型
						imgPath: 'http://tdm-admin.flcccc.com/img/cover6.e7c30c86.jpg',
						isLoading: false,
						imgLoading: false
					},
					valuationMethod: { // 专业的估值方法
						imgPath: 'http://tdm-admin.flcccc.com/img/cover6.e7c30c86.jpg',
						isLoading: false,
						imgLoading: false
					},
					targetSystem: { // 指标体系
						imgPath: 'http://tdm-admin.flcccc.com/img/cover6.e7c30c86.jpg',
						isLoading: false,
						imgLoading: false
					},
					productList: [{
							reportName: '初创期估值',
							reportStage: 1,
							reportType: '',
							reportExample: ''
						},
						{
							reportName: '成长期企业专业估值',
							reportStage: 2,
							reportType: 2,
							reportExample: ''
						},
						{
							reportName: '成熟期企业专业估值',
							reportStage: 3,
							reportType: 2,
							reportExample: ''
						},
						{
							reportName: '并购估值',
							reportStage: 4,
							reportType: '',
							reportExample: ''
						},
						{
							reportName: '专精特新估值',
							reportStage: 7,
							reportType: '',
							reportExample: ''
						}
					]


				}


			}
		},
		mounted() {
			// this.isShow = true
			this.getInfo(2, 1)
		},
		methods: {


		}


	}
</script>

<style scoped lang="less">
	.name {
		font-size: 20px;
		font-weight: bold;
		margin-bottom: 12px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	/deep/ .description {
		font-size: 14px;
		line-height: 24px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}

	.operate {
		display: flex;
		align-items: center;

		button,
		a {
			height: 30px;
			line-height: 30px;
			font-size: 14px;
			text-align: center;
			outline: none;
		}

		button {
			margin-right: 16px;
		}

	}

	.one_product {
		width: 100%;
		height: 264px;
		padding: 30px;
		background: var(--mainColor);
		margin-top: 60px;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		img {
			width: 581px;
			height: 264px;
			object-fit: cover;
			position: absolute;
			top: -30px;
			right: 30px;
		}

		.introduce {
			width: 529px;
		}

		.name {
			color: #fff;
		}

		/deep/ .description {
			width: 529px;
			color: #fff;
		}

		.operate {
			margin-top: 25px;
		}

		button,
		a {
			width: 200px;
			border: 1px solid #fff;
		}

		button {
			background: #fff;
			color: var(--mainColor);
		}

		a {
			color: #fff;
		}

		button:hover,
		a:hover {
			text-decoration: underline !important;
		}

	}


	/deep/ .multipleProducts:not(.threeProducts) {
		>li:nth-child(odd) {
			margin-right: 20px;
		}

		>li {
			width: 590px;
			height: 197px;
		}

		.description {
			width: 376px;
		}

		.operate {
			margin-top: 25px;
		}

	}

	/deep/ .multipleProducts {
		display: flex;
		flex-wrap: wrap;

		.name,
		.description {
			color: #282828;
		}

		>li {
			position: relative;
			background: #FFFFFF;
			background-size: cover !important;
			box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
			margin-bottom: 20px;
			padding: 30px;
		}

		button,
		a {
			width: 165px;
		}

	}

	/deep/ .threeProducts {
		.name {
			width: 100%;
			height: 90px;
			padding: 32px 30px;
			background: pink;
			background-size: cover;
			position: absolute;
			top: 0;
			left: 0;
		}

		.description {
			margin-top: 75px;
			margin-bottom: 15px;
		}

		>li:nth-child(-n+2) {
			margin-right: 20px;
		}

		>li {
			width: 386px;
			height: 227px;
			background: #F4F7FA;
			padding: 30px 20px;
		}

	}

	.why {

		img {
			width: 100%;
			object-fit: cover;
		}
	}

	.valueModel {
		text-align: center;

		img {
			width: 888px;
			height: 437px;
			object-fit: cover;
		}
	}

	.targetSystem {
		margin-bottom: 75px;

		div {
			width: 1200px;
			height: 673px;
			box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
			border: 1px solid #fff;
			margin: 0 auto;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	body[hue='3'] {
		// .multipleProducts {
		// 	>:nth-child(1) {
		// 		background: url(../../assets/img/numberQuotient/businessModelRed1.jpg) center center no-repeat;
		// 	}

		// }
	}
</style>