<template>
	<!-- v-if="isShow" -->
	<div>
		<Header />
		<Carousel v-model="value" loop :height="450" arrow="never" :dots="pageSetup.bannerList.length>1?'inside':'none'">
			<CarouselItem v-for="(item,index) in pageSetup.bannerList">
				<div class="banner"
					:style="{'background':'url('+(item.imgPath?item.imgPath:setImg('home/banner','jpg'))+') center center no-repeat'}">
					<div>
						<p v-if="!item.titleIsPicture" class="bannerTitle">{{item.title}}</p>
						<img v-else src="../../assets/img/homeLogo.png" style="width: 450px;margin-bottom: 20px;" />
						<p class="banner_explain" v-if="item.describe.indexOf('|')==-1">{{item.describe}}</p>
						<p class="banner_explain" v-else v-for="(des,d) in item.describe.split('|').slice(0,5)"
							style="margin-bottom: 13px;">
							<i class="iconfont icon-icon--yes"></i>
							<span>{{des}}</span>
						</p>
						<button class="buttonFill" v-if="$store.state.user.isLogining" @click="login">{{item.btnText}}</button>
					</div>
				</div>
			</CarouselItem>
		</Carousel>
		<ul class="threeMajorSystems" v-if="pageSetup.bannerTag">
			<li>
				<p>
					<img :src="setImg('home/bannerLabel1','png')">
					<span>2025年</span>
				</p>
				<p class="introduce">数据要素“攻坚年”, 数据业务大爆发年</p>
			</li>
			<li>
				<p>
					<img :src="setImg('home/bannerLabel2','png')">
					<span>2030年</span>
				</p>
				<p class="introduce">数据要素价值充分释放，高质量全国统一数据大市场建成</p>
			</li>
			<li>
				<p>
					<img :src="setImg('home/bannerLabel3','png')">
					<span>2035年</span>
				</p>
				<p class="introduce">数据强国，引领世界数字经济</p>
			</li>
		</ul>


		<div class="mainContent">
			<ul class="appraisal">
				<li class="numberQuotient" v-if="pageSetup.numberQuotient&&pageSetup.showNumberQuotient==1">
					<p class="fontColor">数商测试</p>
					<p>修炼数商是智能时代的新潮流，快来测试一下你的数商有多高？</p>
					<router-link tag="button" class="buttonBorder" to="/numberQuotient">立即测试</router-link>
				</li>
				<li class="digitization" v-if="pageSetup.quotientEvaluation&&pageSetup.showQuotientEvaluation==1">
					<p class="fontColor">数字化企业商业模式测评</p>
					<p>支持各类客户价值的商业模式，快来看看你的企业符合几点？</p>
					<router-link tag="button" class="buttonBorder" to="/digitalModelEvaluation">立即测评</router-link>
				</li>
			</ul>

			<div v-if="productShow(3)">
				<p class="moduleTitle">{{productShow(3).groupName}}</p>
				<div class="bp"
					:style="{'background':'url('+(pageSetup.bpProduct.imgPath?pageSetup.bpProduct.imgPath:setImg('home/bpBJ','jpg'))+') center center no-repeat'}">
					<div>
						<img :src="setImg('home/bp','png')" />
						<p>运用大模型和AIGC技术 一键生成打动投资人的商业计划书</p>
						<div class="fillCompanyName" style="position: relative;">
							<input type="text" v-model="companyName" @input="fuzzyQuery" />

							<div id="getCompanyNameList">
								<p v-for="(item,i) in getCompanyNameList" :key="i" :data-area="item.base"
									@click="checkedName(item.name,item.base)">
									{{item.name}}
								</p>
							</div>

							<div class="buttonFill creatBP" @click="creatIndustry(companyName)">立即生成BP</div>
						</div>
					</div>
				</div>
			</div>

		</div>



		<!-- 项目估值 -->
		<div v-if="productShow(1)">
			<p class="moduleTitle">{{productShow(1).groupName}}</p>
			<div v-if="productShow(1).productList.length==1" class="valuation_one_product"
				:style="{'background':'url('+(pageSetup.valuationProduct.imgPath?pageSetup.valuationProduct.imgPath:setImg('home/valuation/one','jpg'))+') center center no-repeat'}">
				<div>
					<p class="productName">{{productShow(1).productList[0].reportName}}</p>
					<div v-html="valuationDesHtml(productShow(1).productList[0].reportStage)"></div>

					<div class="operate">
						<button class="buttonFill"
							@click="productEntrance(productShow(1).productList[0].reportStage,productShow(1).productList[0].reportType)">立即估值</button>
						<a class="buttonBorderTransparency" target="_blank"
							:href="productShow(1).productList[0].reportExample">查看报告示例</a>
					</div>
				</div>
			</div>

			<div v-else-if="productShow(1).productList.length==3">
				<ul class="multipleProducts threeProducts valuationThree">
					<li v-for="(item,i) in productShow(1).productList" :key="i"
						:style="{'background':'linear-gradient(180deg, '+hexToRgb($store.state.user.subjectColor.mainColor, '0.05')+' 0%, #FFFFFF 100%)'}">
						<img :src="setImg('home/valuation/three'+(i+1),'png')" alt="估值" />
						<p class="productName">{{item.reportName}}</p>
						<div v-html="valuationDesHtml(item.reportStage)"></div>
						<div class="operate">
							<button class="buttonFill" @click="productEntrance(item.reportStage,item.reportType)">立即估值</button>
							<a class="buttonBorder" target="_blank" :href="item.reportExample">查看报告示例</a>
						</div>
					</li>
				</ul>
			</div>
			<div v-else>
				<ul class="multipleProducts valuationMulti">
					<li v-for="(item,i) in productShow(1).productList" :key="i"
						:style="{'background':'linear-gradient(180deg, '+hexToRgb($store.state.user.subjectColor.mainColor, '0.05')+' 0%, #FFFFFF 100%)'}">
						<img :src="setImg('home/valuation/four'+(i+1),'png')" alt="估值" />
						<p class="productName">{{item.reportName}}</p>
						<div v-html="valuationDesHtml(item.reportStage)"></div>
						<div class="operate">
							<button class="buttonFill" @click="productEntrance(item.reportStage,item.reportType)">立即估值</button>
							<a class="buttonBorder" target="_blank" :href="item.reportExample">查看报告示例</a>
						</div>
					</li>
				</ul>
			</div>
		</div>

		<!-- 项目诊断 -->
		<div v-if="productShow(2)">
			<p class="moduleTitle">{{productShow(2).groupName}}</p>

			<div v-if="productShow(2).productList.length==1" class="diagnose_one_product">
				<img
					:src="pageSetup.diagnoseProduct.imgPath?pageSetup.diagnoseProduct.imgPath:setImg('home/diagnose/one','jpg')" />
				<div>
					<p class="productName">{{productShow(2).productList[0].reportName}}</p>
					<div v-html="diagnoseDesHtml(productShow(2).productList[0].reportStage)"></div>

					<div class="operate">
						<button
							@click="productEntrance(productShow(2).productList[0].reportStage,productShow(2).productList[0].reportType)">立即诊断</button>
						<a target="_blank" :href="productShow(2).productList[0].reportExample">查看报告示例</a>
					</div>
				</div>
			</div>
			<div v-else-if="productShow(2).productList.length==2">
				<ul class="multipleProducts threeProducts diagnose_two_product">
					<li :style="{'background':'url('+setImg('home/diagnose/two','jpg')+') center center no-repeat'}">
						<p class="productName">诊断报告</p>
						<p style="font-size: 16px;margin-bottom: 28px;">项目管理的工具指南</p>
						<p>全方位解析经营管理状况</p>
						<p>发现影响企业价值的内外部因素</p>
						<p>提出专业改进发展建议</p>
						<p>针对性提高企业核心竞争力</p>
					</li>
					<li v-for="(item,i) in productShow(2).productList" :key="i">
						<p class="productName">{{item.reportName}}</p>
						<div v-html="diagnoseDesHtml(item.reportStage)"></div>
						<div class="operate">
							<button class="buttonFill" @click="productEntrance(item.reportStage,item.reportType)">立即诊断</button>
							<a class="buttonBorder" target="_blank" :href="item.reportExample">查看报告示例</a>
						</div>
					</li>
				</ul>
			</div>
			<div v-else class="diagnoseMulti"
				:style="{'background':'url('+setImg('home/diagnose/three','jpg')+') center center no-repeat'}">
				<div>
					<div class="generalize">
						<p class="productName">诊断报告</p>
						<p style="font-size: 16px;margin-bottom: 28px;">项目管理的工具指南</p>
						<p>全方位解析经营管理状况</p>
						<p>发现影响企业价值的内外部因素</p>
						<p>提出专业改进发展建议</p>
						<p>针对性提高企业核心竞争力</p>
					</div>
					<ul>
						<li v-for="(item,i) in productShow(2).productList" :key="i" style="width: 286px;height: 380px;">
							<p class="productName">{{item.reportName}}</p>
							<div v-html="diagnoseDesHtml(item.reportStage)"></div>
							<div class="operate">
								<button class="buttonFill" @click="productEntrance(item.reportStage,item.reportType)">立即诊断</button>
								<a class="buttonBorder" target="_blank" :href="item.reportExample">查看报告示例</a>
							</div>
						</li>
					</ul>
				</div>
			</div>

		</div>

		<!-- 项目分析（在线尽调、财务预警） -->
		<div v-if="productShow(4)">
			<p class="moduleTitle">{{productShow(4).groupName}}</p>
			<div v-if="productShow(4).productList.length==1" class="analyses_one_product">
				<img
					:src="pageSetup.analyseProduct.imgPath?pageSetup.analyseProduct.imgPath:setImg('home/analyse/one','jpg')" />
				<div v-for="(item,i) in productShow(4).productList" :key="i">
					<p class="productName">{{item.reportName}}</p>
					<div v-html="valuationDesHtml(item.reportStage,item.reportType)"></div>
					<div class="operate">
						<button class="buttonFill" @click="productEntrance(item.reportStage,item.reportType)">立即填写</button>
						<a class="buttonBorder" target="_blank" :href="item.reportExample">查看报告示例</a>
					</div>
				</div>
			</div>
			<ul v-else class="multipleProducts analysesTwo">
				<li v-for="(item,i) in productShow(4).productList" :key="i">
					<img :src="setImg('home/analyse/two'+(i+1),'png')" />
					<p class="productName">{{item.reportName}}</p>
					<div v-html="valuationDesHtml(item.reportStage,item.reportType)"></div>
					<div class="operate">
						<a class="buttonBorder" target="_blank" :href="item.reportExample">查看报告示例</a>
						<button class="buttonFill" @click="productEntrance(item.reportStage,item.reportType)">立即填写</button>
					</div>
				</li>
			</ul>
		</div>

		<!-- Ai数据 -->
		<div v-if="productShow(7)">
			<p class="moduleTitle">{{productShow(7).groupName}}</p>

			<div v-if="productShow(7).productList.length==1" class="data_one_product">
				<img :src="pageSetup.dataProduct.imgPath?pageSetup.dataProduct.imgPath:setImg('home/data/one','jpg')" />
				<div v-for="(item,i) in productShow(7).productList" :key="i">
					<p class="productName">{{item.reportName}}</p>
					<div v-html="valuationDesHtml('',item.reportType)"></div>
					<div class="operate">
						<button class="buttonFill" @click="productEntrance(item.reportStage,item.reportType)">
							{{item.reportType==98?'立即填写':item.reportType==14?'立即测评':'立即估值'}}
						</button>
						<a class="buttonBorderTransparency" target="_blank" :href="item.reportExample">查看报告示例</a>
					</div>
				</div>
			</div>
			<div v-else-if="productShow(7).productList.length==3">
				<ul class="multipleProducts threeProducts dataThree">
					<li v-for="(item,i) in productShow(7).productList" :key="i" style="width: 386px;height: 293px;">
						<p class="productName"
							:style="{'background':'url('+setImg('home/data/three'+(i+1),'jpg')+') center center no-repeat'}">
							{{item.reportName}}
						</p>
						<div v-html="valuationDesHtml('',item.reportType)"></div>
						<div class="operate">
							<button class="buttonFill" @click="productEntrance(item.reportStage,item.reportType)">
								{{item.reportType==98?'立即填写':item.reportType==14?'立即测评':'立即估值'}}
							</button>
							<a class="buttonBorder" target="_blank" :href="item.reportExample">查看报告示例</a>
						</div>
					</li>
				</ul>
			</div>
			<div v-else>
				<ul class="multipleProducts dataThree">
					<li v-for="(item,i) in productShow(7).productList" :key="i" style="width: 590px;height: 277px;">
						<p class="productName"
							:style="{'background':'url('+setImg('home/data/four'+(i+1),'jpg')+') center center no-repeat'}">
							{{item.reportName}}
						</p>
						<div v-html="valuationDesHtml('',item.reportType)"></div>
						<div class="operate">
							<button class="buttonFill" @click="productEntrance(item.reportStage,item.reportType)">
								{{item.reportType==98?'立即填写':item.reportType==14?'立即测评':'立即估值'}}
							</button>
							<a class="buttonBorder" target="_blank" :href="item.reportExample">查看报告示例</a>
						</div>
					</li>
				</ul>
			</div>
		</div>






		<div v-if="pageSetup.showDataRealization==1" class="mainContent">
			<p class="moduleTitle">{{pageSetup.dataRealizationTitle}}</p>
			<ul class="dataRealizationType">
				<li v-for="(item,index) in dataRealizationType" :key="index" :class="[slideIndex === item.type?'active':'']"
					@click="selectLiquidationType(item.type)">
					{{item.name}}
				</li>
			</ul>
			<div v-if="records.length==0" class="nothing" style="height: 300px;">
				<div v-if="nothing">
					<img :src="setImg('nothing','png')" alt="暂无信息" style="margin-top: 40px;" />
					<p>暂无信息</p>
				</div>
			</div>

			<div v-else style="min-height: 300px;">
				<ul class="dataMonetizationProducts dataAssetAPI" v-if="slideIndex==7|| slideIndex==4||slideIndex==1">
					<li v-for="(item,index) in records">
						<img :src="item.logo" :style="{'height':slideIndex==7?'49px':'30px'}" />
						<p class="dataProductsName">{{item.name}}</p>
						<p class="dataProductsDescribe">{{item.profile}}</p>
						<button class="buttonBorderTransparency" @click="viewDetails(item.id)">查看详情</button>
					</li>
				</ul>
				<ul class="dataMonetizationProducts dataAssetPledgeFinancing" v-if="slideIndex==5">
					<li v-for="(item,index) in records">
						<img :src="item.logo" alt="产品logo" />
						<p class="dataProductsName">{{item.name}}</p>
						<div class="detailed">
							<p>
								<span>
									<i class="iconfont icon-shangshengjiantou"
										:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
								</span>
								<span>年利率：{{item.rate}}</span>
							</p>
							<p>
								<span>
									<i class="iconfont icon-a-9xinyongqixianedushenpi"
										:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
								</span>
								<span>贷款期限：{{item.term}}</span>
							</p>
							<p>
								<span>
									<i class="iconfont icon-mianfeitixianedu"
										:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
								</span>
								<span>贷款额度：{{item.loan}}</span>
							</p>
						</div>
						<button class="buttonBorderTransparency" @click="viewDetails(item.id)">查看详情</button>
					</li>
				</ul>
				<ul class="dataMonetizationProducts dataAssetPledgeFinancing" v-if="slideIndex==3">
					<li v-for="(item,index) in records">
						<img :src="item.logo" alt="产品logo" />
						<p class="dataProductsName">{{item.name}}</p>
						<div class="detailed">
							<p>
								<span>
									<i class="iconfont icon-shouyi"
										:style="{'color':$store.state.user.subjectColor.mainColor,'fontSize':'18px'}"></i>
								</span>
								<span>客户买入到期收益率：{{item.rate}}</span>
							</p>
							<p>
								<span>
									<i class="iconfont icon-a-9xinyongqixianedushenpi"
										:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
								</span>
								<span>贷款期限：{{item.term}}</span>
							</p>
							<p>
								<span>
									<i class="iconfont icon-shengyuliang" :style="{'color':$store.state.user.subjectColor.mainColor}"></i>
								</span>
								<span>剩余期限：{{item.residueTerm}}</span>
							</p>
						</div>
						<button class="buttonBorderTransparency" @click="viewDetails(item.id)">查看详情</button>
					</li>
				</ul>
				<ul class="dataMonetizationProducts dataAssetPledgeFinancing" v-if="slideIndex==2">
					<li v-for="(item,index) in records">
						<img :src="item.logo" alt="产品logo" />
						<p class="dataProductsName">{{item.name}}</p>
						<div class="detailed">
							<p>
								<span>
									<i class="iconfont icon-xiangmujine" :style="{'color':$store.state.user.subjectColor.mainColor}"></i>
								</span>
								<span>发行金额：{{item.price}}</span>
							</p>
							<p>
								<span>
									<i class="iconfont icon-a-piaomianxuanzhong"
										:style="{'color':$store.state.user.subjectColor.mainColor}"></i>
								</span>
								<span> 票面利率：{{item.rate}}</span>
							</p>
							<p>
								<span>
									<i class="iconfont icon-yingyeqixian" :style="{'color':$store.state.user.subjectColor.mainColor}"></i>
								</span>
								<span>发行期限：{{item.term}}</span>
							</p>
						</div>
						<button class="buttonBorderTransparency" @click="viewDetails(item.id)">查看详情</button>
					</li>
				</ul>
				<ul class="dataMonetizationProducts dataAssetPledgeFinancing" v-if="slideIndex==6">
					<li v-for="(item,index) in records">
						<div class="logo">
							<img :src="item.logo" alt="产品logo" />
						</div>
						<p class="dataProductsName">{{item.name}}</p>
						<div class="detailed">
							<p>
								<span>
									<i class="iconfont icon-xingye"
										:style="{'color':$store.state.user.subjectColor.mainColor,'fontSize':'18px'}"></i>
								</span>
								<span>行业：{{item.industry}}</span>
							</p>
							<p>
								<span>
									<i class="iconfont icon-dizhi"
										:style="{'color':$store.state.user.subjectColor.mainColor,'fontSize':'18px'}"></i>
								</span>
								<span>地址：{{item.address}}</span>
							</p>
						</div>
						<button class="buttonBorderTransparency" @click="viewDetails(item.id)">查看详情</button>
					</li>
				</ul>

			</div>


		</div>


		<div class="cooperativePartner" v-if="pageSetup.showPartner==1"
			:style="{'background':'url('+setImg('home/partner','jpg')+') center center no-repeat'}">
			<div>
				<p>{{pageSetup.partnerTitle}}</p>
				<ul>
					<li v-for="item in randomImages">
						<img :src="item.logo" alt="" />
					</li>
				</ul>
			</div>
		</div>

		<!-- 	<ul style="display: flex;" v-if="newArry.length>0">
			<li v-for="item in newArry" style="margin-right: 20px;width: 100px;">
				<img :src="item.logo" alt="" style="width: 100%;height: 100%;object-fit: contain;" />
			</li>
		</ul> -->

		<SignUp />
		<Footer />

	</div>
</template>

<script>
	import Header from '../../common/header/flow_header.vue'
	import Footer from '../../common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'
	import '@/assets/css/animate.css'

	import qs from 'qs'
	import axios from 'axios'

	import {
		Spin
	} from 'iview'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp
		},
		data() {
			return {
				nothing: true,
				companyName: '',
				getCompanyNameList: [],
				pageSetup: {
					bannerList: [],
					dataEvaluationContent: {
						imgPath: '',
						title: '',
						padf: '',
						describe: []
					},
					dataValuationContent: {
						imgPath: '',
						title: '',
						padf: '',
						describe: []
					},
					productGroup: []
				},
				dataRealizationType: [{
					name: '数据资产API',
					type: 7
				}, {
					name: '股权融资',
					type: 6
				}, {
					name: '数据资产质押融资',
					type: 5
				}, {
					name: '数据资产通证',
					type: 4
				}, {
					name: '数据资产债券融资',
					type: 3
				}, {
					name: '数据资产证券化',
					type: 2
				}, {
					name: '数据保险',
					type: 1
				}],
				slideIndex: 1,
				value: 0,
				records: [],
				cooperativePartner: [],
				displayedImages: [],
				randomImages: [],
				newArry: []


			}
		},
		mounted() {
			this.getInfo(1)
			this.queryPartnerList()
			this.getDataProductTypeList()


			sessionStorage.removeItem('isHistoryCache')
			sessionStorage.removeItem('netWorth')

			$(document).on('mouseenter', '.cooperativePartner li', function() {
				$(this).addClass('animated flipInX').one('animationend', function() {
					$(this).removeClass('animated flipInX');
					// $(this).removeClass('animated flipInX');
				});
			});

			$(document).on('mouseleave', '.cooperativePartner li', function() {
				$(this).addClass('animated flipInX').one('animationend', function() {
					$(this).removeClass('animated flipInX');
				});
			});



		},
		methods: {
			selectLiquidationType(type) {
				this.slideIndex = type
				this.findDataProduct(1, 4, type)
			},
			getDifferenceArray(arrA, arrB) {
				// 使用 filter() 方法和 some() 方法来判断数组 A 不包含数组 B 中的内容
				var differenceArray = arrB.filter(itemB => !arrA.some(itemA => itemA.logo === itemB.logo));

				return differenceArray;
			},
			getRandomSubarray(arr, size) {
				const shuffled = arr.slice(0);
				let i = arr.length;
				if (i < size) {
					console.log('数组元素个数小于需要随机选取的元素个数。');
				}
				const min = i - size;
				for (let j = min; j > 0; j--) {
					const index = Math.floor(Math.random() * (i--));
					[shuffled[index], shuffled[i]] = [shuffled[i], shuffled[index]];
				}

				return shuffled.slice(min);
			},
			queryPartnerList() { //合作伙伴
				var this_ = this

				this.$post(this.domain.dataRealization + 'cooperativePartner/getPartnerList', qs.stringify({
						channelCode: window.channel
					}))
					.then((res) => {
						this.cooperativePartner = res.data.content
						if (res.data.content.length < 5) {
							this.randomImages = res.data.content
						} else {
							this.animationEffect()
						}
					});
			},
			animationEffect() {
				var this_ = this
				if (this.cooperativePartner.length > 0 && this.cooperativePartner.length > 10) {
					// setInterval  setTimeout
					this.randomImages = this.getRandomSubarray(this.cooperativePartner, 10);
					this.newArry = this.getDifferenceArray(this.randomImages, this.cooperativePartner)

					setInterval(() => {
						var randomImg = this_.randomImages[Math.floor(Math.random() * this_.randomImages.length)]
						var index = this_.randomImages.findIndex(item => item.logo === randomImg.logo);


						$(document).find('.cooperativePartner li').eq(index).addClass('animated fadeOut').one(
							'animationend',
							function() {
								$(this).removeClass('animated fadeOut');
								var randomIndex2 = Math.floor(Math.random() * this_.newArry.length);
								var temp = this_.randomImages[index];
								this_.$set(this_.randomImages, index, this_.newArry[randomIndex2]);
								this_.$set(this_.newArry, randomIndex2, temp);

							});
					}, 5000)
				} else if (this.cooperativePartner.length > 0 && this.cooperativePartner.length <= 10) {
					this_.randomImages = this.cooperativePartner
					// console.log('没有替换替换位置------', this_.randomImages)

					setInterval(() => {
						// 随机生成两个不重复的索引
						var randomIndex1 = Math.floor(Math.random() * this_.randomImages.length);
						var randomIndex2 = Math.floor(Math.random() * this_.randomImages.length);
						while (randomIndex2 === randomIndex1) {
							randomIndex2 = Math.floor(Math.random() * this_.randomImages.length);
						}

						// 交换数组元素位置
						var temp = this_.randomImages[randomIndex1];
						this_.$set(this_.randomImages, randomIndex1, this_.randomImages[randomIndex2]);
						this_.$set(this_.randomImages, randomIndex2, temp);

						$(document).find('.cooperativePartner li').eq(randomIndex2).addClass('animated fadeOut').one(
							'animationend',
							function() {
								$(this).css('animation-duration', '1s !important').removeClass('animated fadeOut')
							});

						// $(document).find('.cooperativePartner li').eq(randomIndex1).addClass('animated fadeOut').one(
						// 	'animationend',
						// 	function() {
						// 		$(this).css('animation-duration', '1s !important').removeClass('animated fadeOut')
						// 	});

						// console.log('小于10替换位置------', this_.randomImages)

					}, 5000)

				}
			},
			login() {
				this.$router.push({
					name: 'login'
				})
			},
			getDataProductTypeList() {
				this.$post(this.domain.dataRealization + 'dataProductType/getDataProductTypeList', qs.stringify({}))
					.then((res) => {
						this.dataRealizationType = res.data.content.slice(0, 7)
						this.slideIndex = res.data.content[0].type
						this.findDataProduct(1, 4, res.data.content[0].type)

					});
			},
			findDataProduct(pageNum, pageSize, type) {
				this.records = []
				this.nothing = false
				this.$post(this.domain.dataRealization + 'dataProduct/getDataProductList', qs.stringify({
						pageNum: pageNum,
						pageSize: pageSize,
						type: type,
						status: '3'
					}))
					.then((res) => {
						this.records = res.data.content.records
						if (res.data.content.records.length == 0) this.nothing = true
					});
			},
			viewDetails(id) {
				this.$router.push({
					path: 'dataCashProductDetails',
					query: {
						productId: id
					}
				});
			},
			productShow(groupId) {
				// console.log('产品组-----', this.pageSetup.productGroup)
				let found = this.pageSetup.productGroup.find(item => item.groupId == groupId);

				// console.log(found)

				return found
			}





		}

	}
</script>

<style scoped lang="less">
	.appraisal {
		display: flex;
		justify-content: space-between;
		margin-top: 51px;

		li {
			width: 590px;
			height: 172px;
			background: #FFFFFF;
			border: 2px solid #FFFFFF;
			padding: 29px 0 0 31px;
			box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
			box-sizing: border-box;

			p:first-child {
				font-size: 20px;
				font-weight: bold;
				margin-bottom: 7px;
			}

			p:nth-of-type(2) {
				width: 282px;
				font-size: 14px;
				color: #282828;
				line-height: 20px;
				margin-bottom: 17px;
			}

			button {
				width: 110px;
				height: 30px;
			}
		}

		.numberQuotient {
			background: url(../../assets/img/home/numberQuotient.png) center center no-repeat;
			background-size: cover;
		}

		.digitization {
			background: url(../../assets/img/home/digitization.png) center center no-repeat;
			background-size: cover;
		}

	}

	.evaluate {
		display: flex;


		>li {
			display: flex;
			background: #F4F7FA;
			box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
			border: 2px solid #fff;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.pictureIdentification {
			width: 738px;
		}

		.evaluateIntroduce {
			width: calc(100% - 738px);
			padding: 30px 50px 0;
			box-sizing: border-box;

			>p {
				font-size: 20px;
				font-weight: bold;
				margin-bottom: 30px;
				color: #282828;
			}

			button {
				width: 320px;
				height: 36px;
				margin-bottom: 16px;
			}

			.describe {

				li {
					margin-bottom: 27px;
					line-height: 20px;

					span {
						width: 90%;
						font-size: 14px;
						color: #282828;
						margin-left: 13px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
		}
	}

	.dataValuationEntry {
		display: flex;
		justify-content: space-between;

		li {
			width: 590px;
			border: 2px solid #FFFFFF;
			box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);

			.name {
				width: 100%;
				height: 130px;
				line-height: 130px;
				padding: 0 30px;
				font-size: 20px;
				font-weight: bold;
				color: #282828;
				background-size: cover !important;
				border-bottom: 2px solid #FFFFFF;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.describe {
				background: #F4F7FA;
				padding: 20px 28px 30px 28px;

				p {
					line-height: 27px;
					font-size: 14px;
					color: #282828;
					margin-bottom: 20px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
				}

				>div {

					button {
						width: 255px;
						height: 36px;
					}

					button:first-child {
						margin-right: 20px;
					}
				}
			}

		}
	}

	.dataRealizationType {
		display: flex;
		justify-content: space-between;

		li {
			padding-bottom: 10px;
			font-weight: bold;
			font-size: 20px;
			color: #282828;
			cursor: pointer;
		}

		li.active {
			color: var(--mainColor);
			border-bottom: 2px solid var(--mainColor);
		}
	}

	.dataMonetizationProducts {
		margin-top: 20px;
		display: flex;

		li {
			width: 285px;
			background: #F4F7FA;
			border: 2px solid #FFFFFF;
			box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
			margin-right: 20px;
			padding: 30px 0;

			button {
				width: 110px;
				height: 30px;
				margin: 20px auto 0;
				display: block;
			}

			.dataProductsName {
				width: 87%;
				font-size: 20px;
				font-weight: bold;
				color: #282828;
				text-align: center;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				margin: 0 auto 15px;
			}

			.dataProductsDescribe {
				width: 87%;
				min-height: 72px;
				font-size: 14px;
				color: #282828;
				line-height: 24px;
				margin: 0 auto;
				text-align: justify;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
			}

			.companyName {
				margin-bottom: 15px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.detailed {
				width: 83%;
				margin: 0 auto;

				p {
					margin-bottom: 15px;
				}

				p:last-child {
					margin-bottom: 0;
				}

				.iconfont {
					font-size: 23px;
				}

				span:first-child {
					width: 25px;
					text-align: center;
				}

				span:last-child {
					font-size: 14px;
					color: #282828;
					margin-left: 11px;
				}

			}
		}

		li:last-child {
			margin-right: 0;
		}
	}

	.dataAssetAPI {
		li {
			img {
				display: block;
				margin: 0 auto 20px;
			}
		}
	}

	.dataAssetPledgeFinancing {
		li {
			>img {
				height: 30px;
				display: block;
				margin: 0 auto 20px;
			}

			.logo {
				width: 100px;
				height: 100px;
				border: 1px solid #CACACA;
				background: #fff;
				padding: 0 10px;
				margin: 0 auto 20px;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}
	}


	.cooperativePartner {
		width: 100%;
		height: 460px;
		background-size: cover !important;
		padding: 54px 0 90px;
		box-sizing: border-box;
		margin-top: 75px;
		margin-bottom: 75px;

		>div {
			width: 1200px;
			margin: 0 auto;

			>p {
				font-size: 28px;
				font-weight: bold;
				color: #fff;
				text-align: center;
				margin-bottom: 50px;
			}

			ul {
				display: flex;
				flex-wrap: wrap;

				.animated {
					animation-duration: 0.5s !important;
					/* 使用!important提高优先级 */
				}

				li {
					width: 210px;
					height: 100px;
					line-height: 100px;
					background: #FFFFFF;
					border-radius: 4px;
					padding: 0 15px;
					box-sizing: border-box;
					margin-right: 37px;
					margin-bottom: 37px;
					text-align: center;

					img {
						max-width: 100%;
						height: 80px;
						object-fit: contain;
						vertical-align: middle;
					}
				}

				li:nth-child(5n) {
					margin-right: 0;
				}
			}
		}
	}

	.bp {
		width: 100%;
		height: 355px;
		background-size: cover !important;
		box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
		padding: 49px;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		#getCompanyNameList {
			top: 36px;
		}

		div:first-child {
			width: 460px;

			>p {
				font-size: 16px;
				color: #282828;
				margin: 16px 0 53px;
			}

			input {
				width: 100%;
				height: 36px;
				background: #FFFFFF;
				border: 1px solid #ccc;
				font-size: 14px;
				color: #282828;
				margin-bottom: 30px;
				padding: 0 10px;
			}

			input:focus {
				border-color: var(--mainColor);
			}

			.creatBP {
				width: 100%;
				height: 36px;
				line-height: 36px;
				text-align: center;
				font-size: 14px;
				color: #fff;
			}

		}
	}

	.productName {
		font-size: 20px;
		font-weight: bold;
		color: #282828;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		position: relative;
		z-index: 3;
	}

	/deep/ .description {
		font-size: 14px;
		color: #282828;
		line-height: 24px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}

	.operate {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		position: relative;
		z-index: 3;

		button,
		a {
			height: 30px;
			line-height: 30px;
			font-size: 14px;
			text-align: center;
			outline: none;
		}

		button {
			margin-right: 16px;
		}

	}

	.multipleProducts {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;

		>li {
			position: relative;
			background: #FFFFFF;
			background-size: cover !important;
			box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
			margin-bottom: 20px;
			overflow: hidden;
		}
	}

	/deep/ .multipleProducts:not(.threeProducts) {
		>li:nth-child(odd) {
			margin-right: 20px;
		}
	}

	/deep/ .threeProducts {
		>li:nth-child(-n+2) {
			margin-right: 20px;
		}
	}

	// 估值------
	.valuation_one_product {
		width: 100%;
		height: 384px;
		background-size: cover !important;
		display: flex;
		justify-content: center;
		align-items: center;

		>div {
			width: 1200px;
		}

		.productName {
			margin-bottom: 23px;
		}

		/deep/ .description {
			width: 473px;
		}

		.operate {
			margin-top: 30px;
		}

		button,
		a {
			width: 140px;
		}
	}

	.valuationThree {
		/deep/ .description {
			width: 326px;
			margin-top: 10px;
		}

		.operate {
			margin-top: 20px;
		}

		button,
		a {
			width: 156px;
		}

		>li {
			width: 386px;
			height: 198px;
			padding: 30px;

			img {
				position: absolute;
				right: 0;
				top: 3px;
			}
		}

	}

	.valuationMulti {
		/deep/ .description {
			width: 372px;
			margin-top: 10px;
		}

		.operate {
			margin-top: 20px;
		}

		button,
		a {
			width: 109px;
		}

		>li {
			width: 590px;
			height: 197px;
			padding: 30px 20px;

			img {
				position: absolute;
				right: 2px;
				top: 17px;
			}
		}
	}

	// 估值------

	// 诊断------
	/deep/ .desTitle {
		margin-bottom: 12px;

		i {
			margin-right: 8px;
		}

		span {
			font-size: 16px;
		}
	}

	/deep/ .introduce {
		width: 275px;
		display: flex;
		flex-wrap: wrap;

		li {
			width: 135px;
			margin-bottom: 12px;

			i {
				margin-right: 8px;
			}

			span {
				font-size: 14px;
			}
		}

		li:nth-child(odd) {
			margin-right: 5px;
		}

	}

	.diagnose_one_product {
		width: 1200px;
		height: 316px;
		background: var(--mainColor);
		margin: 60px auto 0;
		box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: relative;

		img {
			width: 645px;
			height: 316px;
			object-fit: cover;
			position: absolute;
			top: -30px;
			left: 35px;
		}

		.productName {
			color: #fff;
			margin-bottom: 25px;
		}

		>div {
			width: 300px;
			position: relative;
			right: 109px;
			color: #fff;
			color: #fff;
		}

		button,
		a {
			width: 140px;
			cursor: pointer;
			margin-top: 8px;
		}

		button {
			background: #fff;
			color: var(--mainColor);
		}

		a {
			background: var(--mainColor);
			color: #fff;
			border: 1px solid #fff;
		}

		button:hover,
		a:hover {
			text-decoration: underline !important;
		}

	}

	.diagnose_two_product {
		>li {
			width: 386px;
			height: 316px;
			box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
			padding: 30px 40px;
			color: #282828;

			>p {
				color: #282828;
			}

			button,
			a {
				width: 140px;
				margin-top: 8px;
			}

			button {
				margin-right: 26px;
			}

		}

		>li:nth-child(1) {
			background-size: cover !important;

			.productName {
				margin: 14px 0;
			}

			>p:nth-last-child(-n+4) {
				font-size: 14px;
				margin-top: 18px;
			}
		}

		>li:not(:first-child) {
			.productName {
				margin-bottom: 22px;
			}

			/deep/ .desTitle {
				margin-top: 6px;

				i {
					color: var(--mainColor);
				}
			}

			/deep/ .introduce {
				i {
					color: var(--mainColor);
				}

				li {
					margin-bottom: 10px;
				}
			}


		}

	}

	.diagnoseMulti {
		width: 100%;
		height: 486px;
		box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
		background-size: cover !important;
		padding-top: 60px;

		>div {
			width: 1200px;
			margin: 0 auto;
			display: flex;

			.generalize {
				color: #282828;
				margin-right: 104px;

				.productName {
					margin: 69px 0 14px;
				}

				>p:nth-last-child(-n+4) {
					font-size: 14px;
					margin-top: 18px;
				}
			}

		}

		ul {
			display: flex;
			gap: 20px;

			>li {
				background: #FFFFFF;
				box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
				padding: 35px 28px;
				color: #282828;

				.productName {
					margin-bottom: 22px;
				}

				/deep/ .desTitle {
					i {
						color: var(--mainColor);
					}
				}

				/deep/ .introduce {
					i {
						color: var(--mainColor);
					}

				}

				.operate {
					justify-content: center;
				}

				button,
				a {
					width: 212px;
					margin-right: 0;
				}

				button {
					margin: 20px 0 16px;
				}

			}
		}
	}

	// 诊断------

	// 项目分析-------
	.analyses_one_product .productName {
		margin-bottom: 20px;
	}

	.analyses_one_product .operate {
		position: absolute;
		bottom: 30px;

		button,
		a {
			width: 180px;
			height: 30px;
		}

	}

	.analyses_one_product {
		width: 1200px;
		height: 302px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;

		>img {
			width: 682px;
			height: 100%;
			object-fit: cover;
			box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
			border: 2px solid #FFFFFF;
			margin-right: 20px;
		}

		>div {
			width: 498px;
			height: 100%;
			background: #F3F7FA;
			box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
			border: 2px solid #FFFFFF;
			padding: 30px 56px;
			position: relative;
		}
	}

	/deep/ .earlyWarningDes {
		p {
			margin-bottom: 15px;
		}

		span {
			font-size: 14px;
			color: #282828;
		}

		i {
			color: var(--mainColor);
			margin-right: 7px;
		}
	}

	/deep/ .dueDiligenceDes {
		width: 332px;

		>p {
			margin-bottom: 15px;

			span {
				font-size: 14px;
				color: #282828;
			}

			i {
				color: var(--mainColor);
				margin-right: 7px;
			}
		}

		>div {
			display: flex;
			flex-wrap: wrap;
			margin-left: 24px;

			span {
				width: 45%;
				font-size: 14px;
				color: #282828;
				padding-left: 16px;
				margin-bottom: 15px;
				position: relative;
			}

			span::before {
				content: '';
				display: block;
				width: 6px;
				height: 6px;
				background: var(--mainColor);
				border-radius: 50%;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	.analysesTwo {
		.productName {
			margin-bottom: 20px;
		}

		li {
			width: 590px;
			height: 338px;
			padding: 30px;
			background: #fff;

			>img {
				height: 161px;
				position: absolute;
				top: 95px;
				right: 73px;
			}
		}

		.operate {
			width: 212px;

			button,
			a {
				width: 212px;
				height: 30px;
				margin-right: 0;
			}

			a {
				margin-bottom: 16px;
				margin-top: 15px;
			}

		}
	}

	// 项目分析-------


	// Ai数据------
	.data_one_product {
		width: 1200px;
		height: 327px;
		background: #F4F7FA;
		margin: 0 auto;
		box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
		display: flex;
		align-items: center;
		justify-content: flex-end;
		position: relative;
		padding-right: 60px;

		/deep/ .description {
			-webkit-line-clamp: 3;
			margin-top: 21px;
		}

		>img {
			width: 737px;
			height: 327px;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
		}

		>div {
			width: 342px;
		}

		button,
		a {
			width: 341px;
			margin-right: 0;
		}

		button {
			margin-bottom: 16px;
			margin-top: 40px;
		}
	}

	.dataThree {
		.productName {
			height: 130px;
			line-height: 130px;
			background-size: cover !important;
			padding: 0 30px;
			border-bottom: 2px solid #FFFFFF;
		}

		/deep/ .description {
			padding: 20px;
			-webkit-line-clamp: 3;
		}

		>li {
			background: #F4F7FA;
			border: 2px solid #FFFFFF;
		}

		.operate {
			width: 100%;
			padding: 0 16px;
			position: absolute;
			bottom: 20px;
		}

		button,
		a {
			width: calc(50% - 10px);
			height: 36px;
			line-height: 36px;
		}
	}

	// Ai数据------

	/* 3大投行系统 */

	.threeMajorSystems {
		width: 1200px;
		display: flex;
		justify-content: space-between;
		margin: -58px auto 20px;
		position: relative;
	}

	.threeMajorSystems li {
		width: 388px;
		height: 99px;
		background: #F5F7FA;
		box-shadow: 2px 2px 13px 0px rgba(32, 76, 151, 0.23);
		border: 2px solid #FFFFFF;
		padding: 15px;
		box-sizing: border-box;
	}

	.threeMajorSystems li>p img {
		width: 36px;
		margin-right: 16px;
		vertical-align: middle;
	}

	.threeMajorSystems li>p span {
		font-size: 20px;
		font-weight: bold;
		color: #282828;
	}

	.threeMajorSystems li .introduce {
		width: 100%;
		font-size: 14px;
		color: #282828;
		line-height: 26px;
		margin: 5px auto 10px;
		text-align: justify;
	}

	.threeMajorSystems li button {
		width: 144px;
		height: 32px;
		color: #F4F7FA !important;
		font-size: 14px;
	}

	/* 3大投行系统 */


	body[hue='3'] {
		.numberQuotient {
			background: url(../../assets/img/home/numberQuotientRed.jpg) center center no-repeat;
		}

		.digitization {
			background: url(../../assets/img/home/digitizationRed.jpg) center center no-repeat;
		}
	}
</style>